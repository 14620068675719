import * as moment from 'moment';

export function getAuthString(userName: string, password: string, domain: string, publicKey: string, separator: string) {
    const fecha = moment().format('YYYYMMDD');
    const cad = cifrarXor(userName + separator + password + separator + domain + separator + fecha, publicKey);
    const b64cad = Base64.encode(cad);
    return b64cad;
}

export function cifrarXor(value: string, password: string): string {
    let i = 0;
    let j = 0;
    let sCifrado = '';
    if (undefined == password) password = "";
    while (i < value.length) {
        sCifrado += String.fromCharCode(value.charCodeAt(i) ^ password.charCodeAt(j));
        j = (j + 1) % password.length;
        i++;
    }

    return sCifrado;
}

function toBase64(str: string): string {
    return btoa(new TextEncoder().encode(str).reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

// 🔹 Convertir de Base64 a texto de forma segura
function fromBase64(str: string): string {
    return new TextDecoder().decode(Uint8Array.from(atob(str), c => c.charCodeAt(0)));
}
/*export function cifrarXor(text: string, key: string): string {
    let output = '';
    for (let i = 0; i < text.length; i++) {
        output += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return output;
}*/

export var Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",


    encode: function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },


    decode: function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    _utf8_encode: function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    _utf8_decode: function (utftext) {
        var string = "";
        var i = 0;
        var c1, c2, c3;
        var c = c1 = c2 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }

}

export function cifrarObjeto(obj: any, key: string): string {
    const jsonString = JSON.stringify(obj);  // Convertir a JSON
   // const base64Encoded = Base64.encode(jsonString);  // Convertir a Base64
    const base64Encoded = jsonString;  // Convertir a Base64
    const xorCifrado = cifrarXor(base64Encoded, key); // Aplicar XOR
    return toBase64((xorCifrado));    // Aplicar XOR
  }
  
  // Función para descifrar
export function descifrarObjeto(cifrado: string, key: string): any {
    const xorDecodificado = fromBase64(cifrado);
    const base64Decoded = cifrarXor(xorDecodificado, key); // XOR inverso
   // const jsonString = Base64.decode(base64Decoded);        // Base64 inverso
    const jsonString = base64Decoded;        // Base64 inverso
    console.log(jsonString,'jsonString')
    return JSON.parse(jsonString);                 // Convertir a JSON
  }