<div class="content">
  <div class="login">
    <form class="custom-form" method="post">
      <div class="logo">
       <!---- <img class="logo-image" src="assets/img/LoginBanner_soledad.png" />-->
        <img class="logo-image"  src="assets/custom/{{imagepath}}/logo/logo.svg" />
        <h2 class="login-header">1Cal</h2>
      </div>

      <input type="email" [(ngModel)]="cred.email" name="email" placeholder="Usuario" required="required" />
      <input type="password" [(ngModel)]="cred.password" name="password" placeholder="Contraseña" required="required" />
      <div class="content-button">
        <button class="custom-form-button" type="submit" (click)="login(cred)">Iniciar sesión</button>
      </div>

      <div class="error-sesion" *ngIf="loginStatus == serviceStatus.Error">Error al iniciar sesión</div>

      <div class="version">v. 2024-11-15-beta</div>
    </form>
  </div>
  <router-outlet></router-outlet>
</div>
<router-outlet></router-outlet>