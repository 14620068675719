import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import tippy from 'tippy.js';
import { GlobalVariables } from '../services/local/globalVariables.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  private token: string
  workplaces: Array<any> = []
  filterValue = ''
  title="Dashboard";
  imagepath: string=''


  constructor(private api: ApiService, private router: Router, private globalVariables: GlobalVariables, private auth:AuthService) { }

  ngOnInit(): void {

   /* this.api.getWorkPlaces().subscribe(res=>
      {
        if (res) {
          if (res.workPlaces.length === 1) {
            this.clickWorkPlace(res.workPlaces[0].idWorkPlace, res.workPlaces[0].name);
            return;
          }
          res.workPlaces.forEach(workPlace => this.workplaces.push(workPlace));
        }
        //else (err)=>
       // {console.log(err,'error')}
      
      
      })*/


      this.api.service('/workplaces','get').subscribe(res=>{
        if (res) {
          if (res.workPlaces.length === 1) {
            this.clickWorkPlace(res.workPlaces[0].idWorkPlace, res.workPlaces[0].name,res.workPlaces[0].address,res.workPlaces[0].clientErpCode);
            return;
          }
          res.workPlaces.forEach(workPlace => this.workplaces.push(workPlace));
        }
        //else (err)=>
       // {console.log(err,'error')}
      
      
      })


    this.imagepath=this.globalVariables.getUrlImage()

    // tippy('#myButton', { content: "I'm a Tippy tooltip!" ,
    //  placement: 'top',
    // trigger: 'hover'
    //})
 

  }

  clickWorkPlace(idWorkPlace: any, name: any,address:string, clientErpCode?:any) {
       this.router.navigate(['resources/' + idWorkPlace + '/' + name], {queryParams:{address:address, idWorkPlace:idWorkPlace, clientErpCode: clientErpCode}})
     // this.router.navigate(['resources/' + id + '/' + name], {queryParams:{address:workPlace[0].address, idWorkPlace:workPlace[0].idWorkPlace}})
    // this.router.navigate(['resources'])
  }

  clickExit(event) {
    event.preventDefault();
    localStorage.clear()
    this.router.navigate(['login']);
  }

  handleSearch(event) {
    this.filterValue = event
    //console.log(this.filterValue,'filterValue del main')
  }


  ngAfterViewInit() {
    tippy('#myButton', {
      content: "I'm a Tippy tooltip!",
      placement: 'top',
      trigger: 'hover'
    })
  }

  openAsideMenu(event){
    document.getElementById("sideNavigation").style.display="flex";
    document.getElementById("close").style.display = "flex";
    document.getElementById("backdrop").style.display = "flex";
   
  }

  closeNav(event){

    event.stopPropagation();
    event.preventDefault();
    document.getElementById("sideNavigation").style.display = "none";
    document.getElementById("close").style.display = "none";
    document.getElementById("backdrop").style.display = "none";
  
  }

}