import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';


@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css']
})
export class SearchCustomerComponent implements OnInit {
  @Output() Customer = new EventEmitter();
  @Output() closeCustomerSearch = new EventEmitter();
  @Output() cancelMessageEvent = new EventEmitter();
  @Input()  criteria: any;
  @Input() enterprise: any;

  searchText: any =""
  searchRegistration: any =""
  customers: Array<any>=[]
  customersRegistrations: Array<any>=[]
  loading: boolean
  error: boolean;
  msg: string;
  selectedDiv: number | null = null;
  nameCustomer: any;
  registration: any;
  customer: any;
  typeSearch: string =''
  token: any;
  imagepath: string=''

  constructor(private api: ApiService,private globalVariables: GlobalVariables, ) { }

  ngOnInit(): void {

    this.initializeValues()
    this.imagepath=this.globalVariables.getUrlImage()

    if (this.enterprise.length<2)
     this.enterprise= '0' + this.enterprise.toString()


    if (this.criteria.field && this.criteria.value)
    {
      let data=`[{\"SEARCH_TEXT\":\" ${this.searchText}\" ,\"ROWID_DIRECTO\":\"\"}]`
      
      this.createCustomers(data)
    }
 
  }

  closeEvent(item?){
    console.log('entra al close event')
    console.log(item,'item del close event')

    if (item)
      this.closeCustomerSearch.emit({customer:item})
  }

  cancelEvent(event){
   this.cancelMessageEvent.emit({result: 'cancelar'})
  }

  searchCustomer(event?)
  {   
    let data={}
   
    
    if (this.nameCustomer)
      {
        this.searchText=this.nameCustomer
        data=`[{\"SEARCH_TEXT\":\"${this.searchText}\" ,\"ROWID_DIRECTO\":\"\"}]`
       // data=`[{\"SEARCH_TEXT\":\" ${this.searchText}\" ,\"ROWID_DIRECTO\":\"\", \"_CODCLIENTE\":355926}]`
       // data=`[{\"SEARCH_TEXT\":\" \" ,\"ROWID_DIRECTO\":\"CODCLIENTE=624192\"}]`
        this.typeSearch='customer'
        this.criteria.field='name'
      }
     
    else if (this.registration)
      {
        this.searchText= this.registration
        data=`[{\"SEARCH_TEXT\":\" ${this.searchText}\" ,\"ROWID_DIRECTO\":\"\" ,\"CODCLIENTE\":\"\"}]`
          
        this.typeSearch='vehicles'
        this.criteria.field='registration'
      
      }
     

    if(this.searchText!='')
      {
        this.loading=true
           // let data=`[{\"SEARCH_TEXT\":\" ${this.searchText}\" ,\"ROWID_DIRECTO\":\"\"}]`
        this.createCustomers(data, this.typeSearch)
           
            
      }
    else
      {  this.error=true
         this.msg='No hay clientes con el criterio de búsqueda actual'
      }
        
      return;
        
  
  }

  createCustomers(data:any, typeSearch?:any){
    this.api.getTokenGTS().subscribe(res=>{
      this.token=res.value,data
      switch (typeSearch)
      {
        case 'customer':
          this.searchByCustomerName(this.token,data)
          break;

        case 'vehicles':
          this.searchByVehicle(this.token,data)
          break;


      }
    
    })

  }

  searchByCustomerName(token, data){
    let codClientsArr: Array<any>=[]
    this.api.customer(token,data,this.enterprise).subscribe(res=>{
      this.customers=res.value.Rows
      res.value.Rows.forEach(item=>{
        codClientsArr.push(item._CODCLIENTE)
      })
 
      if (this.registration)
        this.searchText=this.registration
      else this.searchText=''


      if (codClientsArr.length>0)
        {
          let data=`[{\"SEARCH_TEXT\":\"${this.searchText}\",\"ROWID_DIRECTO\":\"\", \"codclients\":[${codClientsArr}]}]`
          this.searchByVehicle(token, data)
          this.loading=false
        }
      else{
          this.loading=false
          this.msg='No hay clientes con el criterio de búsqueda actual'
        
      }
     
    })
  }

  searchByVehicle(token, data){
    let registrations: Array<any>=[]
    this.customersRegistrations=[]
    this.api.vehicles(token,data, this.enterprise).subscribe(res=>{
      registrations=res.value.Rows
          registrations.forEach(element=>{
            if (this.customers){
              this.customer=''
              this.customer= findClient(element._CODCLIENTE,this.customers, element)
              if (this.customer)
                this.customersRegistrations.push({_CODCLIENTE: this.customer._CODCLIENTE,NOMCLTE:this.customer.NOMCLTE,TELEFONO:this.customer.TELEFONO,REGISTRATION:element.MATRICULA, EMAIL: this.customer.EMAIL})
              else
              {
                let data=`[{\"SEARCH_TEXT\":\" \" ,\"ROWID_DIRECTO\":\"${element._CODCLIENTE}\"}]`
                this.api.customer(token,data,this.enterprise).subscribe(res=>{
                  if(res.value.Rows.length){
                    this.customers.push(res.value.Rows[0])
                    this.customersRegistrations.push({_CODCLIENTE: this.customers[0]._CODCLIENTE,NOMCLTE:this.customers[0].NOMCLTE,TELEFONO:this.customers[0].TELEFONO,REGISTRATION:element.MATRICULA, EMAIL: this.customers[0].EMAIL})
            
                  }
                  
                })
              }
            
            }
          })
            this.loading=false
         
      })
      
  }

  selectCustomer(event, item){

    if (this.customers.length>0){
      if(item.TELEFONO)
        item.TELEFONO='34'+ item.TELEFONO
    
      this.closeEvent(item)
    }
     
      //this.searchBudgets(item._CODCLIENTE,item)*/
  
  }

  searchBudgets(codCliente, client){
    let budgets: Array<any>=[]
    let data_budget=`[{\"SEARCH_TEXT\":\" \" ,\"ROWID_DIRECTO\":\"\"}]`
   // let context_budget = `[{"ENTERPRISE_CODE":"01", "CUSTOMER_CODE":${codCliente}}]`
    let context_budget = `[{"ENTERPRISE_CODE":"${this.enterprise}", "CUSTOMER_CODE":${codCliente}}]`
    this.api.budgets(this.token,data_budget,context_budget).subscribe(res=>{
          res.value.Rows.forEach(res=>{
              if(client.REGISTRATION==res.MATRICULA)
                  budgets.push(res.NUM_PRESUPUESTO)
            })
          if (budgets.length>0)
            {
              client.budgets=budgets.length>1?budgets:null
              client.budget= budgets[0]
            }
            if(client.TELEFONO)
              client.TELEFONO='34'+ client.TELEFONO
          
            this.closeEvent(client)
        })
    
   
  }

  onKey(value){

    this.searchText= value
    this.searchCustomer()

  }

  initializeValues(){
    this.customers=[];
    this.error=false;
    this.customersRegistrations=[];
    this.msg='No hay clientes con el criterio de búsqueda actual'
    if (this.criteria)
    {
      switch (this.criteria.field)
      {
        case 'name':
          this.nameCustomer= this.criteria.value
          this.searchText=this.nameCustomer
          break;
        case 'registration':
          this.registration= this.criteria.value
          this.searchText=this.registration
          break;
      }
    }

  }

  clearCustomers(event, item){
    event.stopPropagation();
    event.preventDefault();
    this.initializeValues();
 
    this.searchText=''
    this.nameCustomer=''
    this.registration=''
    this.typeSearch=''
    this.criteria={field:'name',value:'' }
    this.loading=false
  }

  highlightDiv(index: number): void {
    this.selectedDiv = index;
  }

}

function findClient(criteria: any, arr: Array<any>, item:any){
  let matchCriteria: any

  for (let i=0; i<arr.length;i++)
  {
     if (arr[i]._CODCLIENTE.toString()== criteria)
     
     {
        arr[i].REGISTRATION=item.MATRICULA
        matchCriteria= arr[i]
        return matchCriteria
     }

  }
  
}
