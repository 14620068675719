import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { ResultLoginStatus } from '../../models/resultLogin';
import { ServiceStatus } from '../../shared/models/serviceStatus';
import { Credentials } from '../../models/credentials';
import { ResultLogin } from '../../models/resultLogin';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { Subscription } from 'rxjs';
import { Base64, cifrarXor } from '../../shared/helpers/utils';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email: string;
  password: string;
  data: any;
  user: User;
  token: string;

  cred = new Credentials({});
  resultLoginStatus = ResultLoginStatus;
  resultLogin: ResultLogin = new ResultLogin();
  serviceStatus = ServiceStatus;
  loginStatus = ServiceStatus.Ini;
  imagepath: string='';
  userNameLoggedSubs: Subscription;
  userNameLogged: any;
  clientInfo: any;
  idDetail: any;
  calendarDetail: any

  encryptSecretKey = "JA#AOO3O2G$8P2LK";


  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private userService: UserService,
    public activatedRouter: ActivatedRoute,
    private http: HttpClient,
    private globalVariables: GlobalVariables
  ) {

    this.auth.resultLogin.subscribe(
      res => {
        if (res) {
          this.resultLogin = res;
          //ERROR
          if (!res || res.status == ResultLoginStatus.Error) {
            this.loginStatus = ServiceStatus.Error;
            return;
          }

          //lOGIN
          if (res.status == ResultLoginStatus.Logged) {
            this.loginStatus = ServiceStatus.Ok
            this.auth.userLogin.subscribe(user=>{
              this.user=user
              this.globalVariables.setUserLogged(this.user)
              this.globalVariables.setUserNameLogged(this.user.userName)
              this.globalVariables.setRol(this.user.role)
                }
               )
            this.router.navigate(['/'])
          }

          //LOGOUT
          if (res.status == ResultLoginStatus.Logout) {
            this.loginStatus = ServiceStatus.Ini;
            this.router.navigate(['/login']);
            return;
          }
        }
      }
    )
  }

  ngOnInit(): void {

   // this.imagepath=localStorage.getItem("urlimage")
    this.imagepath=this.globalVariables.getUrlImage()
  
   // console.log(JSON.parse(cifrarXor(Base64.decode(decodeURI('MWNGLC4mX20IZVBdI0Z9aWZjUyA8PEQgQCMGAnIDfnh+dBVjMg==')), this.encryptSecretKey)),'JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.param)), this.encryptSecretKey))')
  //  console.log(this.activatedRouter.snapshot.params,'this.activatedRouter.snapshot.params')
  //  console.log(JSON.parse(cifrarXor(Base64.decode(decodeURI('MWNANDw7XCJXNQYCKxACBAcCbxUKbQltXiZRSjEQYGkeBG8ECQB9ABB9BgtkBHh/enYXcn1/EWMQFWF/GWEYGQsVag4BbQltBHIRCBR+HmlmY0E0KyhWOxB9BnxnCxN5fm4Xdnt+ETJP')),this.encryptSecretKey)),'JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.client)), this.encryptSecretKey))')
   // console.log(JSON.parse(cifrarXor(Base64.decode(decodeURI('MWNKJQsqRy5bKwYCKxAlLw4kVyAmIxF1EHQUCmILfGlmY0cgOyoRdRB1FApkH315Z3EaYX92CX8CfRQIck8x')),this.encryptSecretKey)),'JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.idDetail)), this.encryptSecretKey))')
    
    if (this.activatedRouter.snapshot.params.param) {
     // this.cred = JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.param)), this.encryptSecretKey));
        this.cred=  JSON.parse((Base64.decode(decodeURIComponent(this.activatedRouter.snapshot.params.param)))),

      //console.log( JSON.parse((Base64.decode((this.activatedRouter.snapshot.params.param)))), 'cred decoded');
      
     // console.log(this.activatedRouter.snapshot.params.param,'this.activatedRouter.snapshot.params.param')
     // console.log(this.cred,'cred')
      //console.log(JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.calendarDetail)), this.encryptSecretKey)),'params.calendarDetail decodificado')
      this.login(this.cred);
      return;
    }

   

    if (localStorage.getItem("saveCred")) {
      this.cred = JSON.parse(cifrarXor(Base64.decode(decodeURIComponent(localStorage.getItem("saveCred"))), this.encryptSecretKey));
    }
   
  }

  login(cred: Credentials) {
    const param={

      email:'USR_1_45',
      password:'J6I7^uRQfqS2Gu'

    }

  
    this.loginStatus = ServiceStatus.Loading;
    this.auth.login(cred);
    this.auth.resultLogin.subscribe(
      res => {
        if (res) {
          if (res.status == this.resultLoginStatus.Logged) {
            this.loginStatus = ServiceStatus.Ok
            this.auth.userLogin.subscribe(user=>{
              this.user=user
              this.globalVariables.setUserLogged(this.user)
              this.globalVariables.setRol(this.user.role)
              localStorage.setItem('role',this.user.role)
                }
               )

  
            localStorage.setItem("saveCred", encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(cred), this.encryptSecretKey))));
            localStorage.setItem("userName", this.user.userName)
       

         // console.log(this.activatedRouter.snapshot.params,'params')
         // console.log(encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(cred), this.encryptSecretKey))),'encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(cred), this.encryptSecretKey)))')

          if (this.activatedRouter.snapshot.params.calendarDetail) {
          //  this.calendarDetail=JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.calendarDetail)), this.encryptSecretKey));
            this.calendarDetail=JSON.parse((Base64.decode(decodeURIComponent(this.activatedRouter.snapshot.params.calendarDetail))));


          }

        
         
        /* if (this.activatedRouter.snapshot.params.idDetail) {
              this.idDetail= JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.idDetail)), this.encryptSecretKey));
             console.log(this.idDetail ,'this.idDetail parsed decoded params desde login')
      
            }*/

    

          /*  if (this.activatedRouter.snapshot.params.client) {
              this.clientInfo = JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.client)), this.encryptSecretKey));
              console.log(this.clientInfo ,'this.clientInfo parsed decoded params desde login')
      
            }*/
        
          //  this.clientInfo={customer:{'NOMCLTE':'laura','TELEFONO':'34644074320','REGISTRATION': '6550DLR','budget': 'D79_24/4741','EMAIL':'lalidenk@gmail.com'}}
           /* const calendarDetail={

              customer:{'NOMCLTE':'laura','TELEFONO':'34644074320','REGISTRATION': '6550DLR','budget': 'D79_24/4741','EMAIL':'lalidenk@gmail.com'},
              idDetail:{'idDetail':'301677','date': '2024-12-09 09:00:00'}

            }*/

           /* const calendarDetail={

              customer:{'NOMCLTE':'laura','TELEFONO':'34644074320','REGISTRATION': '6550DLR','budget': 'D79_24/4741','EMAIL':'lalidenk@gmail.com'},
              idDetail:{'idDetail':'306382','date': '2024-12-30 09:30:00'}

            }*/

           /*  const calendarDetail={

              customer:{'NOMCLTE':'DIAZ BELLIDO ALEJANDRO','TELEFONO':'644262998','REGISTRATION': '9444DJX','ID': '765550','EMAIL':''},

            }*/

          /*  const calendarDetail={

              customer:{'NOMCLTE':'laura','TELEFONO':'34644074320','REGISTRATION': '6550DLR','ID': '765664','EMAIL':'lalidenk@gmail.com'},

            }*/

           /*const calendarDetail={

              idDetail:{'idDetail':'306382','date': '2024-12-30 09:30:00'}

            }*/

            const calendarDetail={

              idDetail:{'idDetail':'328977','date': '2025-02-28 10:30:00'}

            }
          
           /* const calendarDetail={

                customer:{'NOMCLTE':'Rosa','TELEFONO':'34662002856','REGISTRATION': '62889LSS','budget': 'D79_24/4741','EMAIL':''},
                idDetail:{'idDetail':'306382','date': '2024-12-30 09:30:00'}
  
              }*/

            /*  const calendarDetail={
                customer:{'NOMCLTE':'laura','TELEFONO':'34644074320','REGISTRATION': '6550DLR','ID': '4743','EMAIL':'lalidenk@gmail.com'},
                idDetail:{'idDetail':'317246','date': '2025-01-31 10:00:00'}
              }*/

             /*const calendarDetail={

              idDetail:{'idDetail':'319903','date': '2025-02-03 09:00:00'}

            }*/

              console.log(encodeURIComponent(Base64.encode(JSON.stringify(calendarDetail))), 'calendarDetail encoded')
             // console.log(JSON.parse(Base64.decode('eyJjdXN0b21lciI6eyJOT01DTFRFIjoibGF1cmEiLCJURUxFRk9OTyI6IjM0NjQ0MDc0MzIwIiwiUkVHSVNUUkFUSU9OIjoiNjU1MERMUiIsImJ1ZGdldCI6IkQ3OV8yNC80NzQxIiwiRU1BSUwiOiJsYWxpZGVua0BnbWFpbC5jb20ifX0=')),'CALENDAR DEATIL DECODED CON DATOS DEL CLIENTE SIN EL ID DE CITA')
             // console.log(JSON.parse(Base64.decode('eyJjdXN0b21lciI6eyJOT01DTFRFIjoiUm9zYSIsIlRFTEVGT05PIjoiMzQ2NjIwMDI4NTYiLCJSRUdJU1RSQVRJT04iOiI2Mjg4OUxTUyIsImJ1ZGdldCI6IkQ3OV8yNC80NzQxIiwiRU1BSUwiOiIifX0='))), 'CALENDAR DEATIL DECODED CON DATOS DEL CLIENTE SIN EL ID DE CITA'
             console.log(JSON.parse(Base64.decode('eyJpZERldGFpbCI6eyJpZERldGFpbCI6IjMxOTkwMyIsImRhdGUiOiIyMDI1LTAyLTAzIDA5OjAwOjAwIn19')), 'calendarDetail decoded') // SOLO ID DE LA CITA Y FECHA
         //   console.log(JSON.parse(Base64.decode('eyJpZERldGFpbCI6eyJpZERldGFpbCI6IjMwNjM4MiIsImRhdGUiOiIyMDI0LTEyLTMwIDA5OjMwOjAwIn19')), 'calendarDetail decoded') // SOLO ID DE LA CITA Y FECHA

           

         //   console.log(encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(calendarDetail), this.encryptSecretKey))), 'calendarDetail encoded')
           
         //   console.log(encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(this.clientInfo), this.encryptSecretKey))), 'clientInfo encoded')
          //  console.log(encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify({idDetail:{'idDetail':'306382', 'date': '2024-12-30 09:30:00'}}), this.encryptSecretKey))), 'idcalendardetail encoded')

           // console.log(encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify({idDetail:{'idDetail':'319903', 'date': '2024-12-31 10:00:00'}}), this.encryptSecretKey))), 'idcalendardetail encoded')
           // this.router.navigate(['main'],{queryParams:{clientInfo:JSON.stringify(this.clientInfo), idDetail: JSON.stringify(this.idDetail)}})
            this.router.navigate(['main'],{queryParams:{calendarDetail:JSON.stringify(this.calendarDetail)}})
          }
          else {
            if (res.status == this.resultLoginStatus.Error) {
              this.loginStatus = ServiceStatus.Error
              this.router.navigate(['login'])
            }
          }
        }
      }
    )
  }
}