import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ViewContainerRef ,ElementRef, SimpleChanges, ViewEncapsulation,AfterViewInit, ChangeDetectorRef,  } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { addHourToStr, calculateTimeofRangeHours, validateDatesEN, parseDate, parseDateToTime,parseDateTime,parseDateTimeZ,transfromDateTimeENT, recalculateArrHours, transfromDateEN, transfromStringtoDate, getTimeNumber  } from '../../shared/utilities/utilities'
import { DatePipe } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { ShowMessageComponent } from 'src/app/shared/modal/show_message/show-message.component';
import { Recurrence } from 'src/app/models/recurrence';
import { SeriesEventsComponent } from 'src/app/shared/modal/series-events/series-events.component';
import { HttpClient } from '@angular/common/http';
import { Subscriber } from 'src/app/shared/helpers/subscriber';
import { SearchCustomerComponent } from 'src/app/shared/modal/search-customer/search-customer/search-customer.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from 'src/app/shared/notifications/bottom-sheet/bottom-sheet.component';
//import intlTelInput from 'intl-tel-input'
import intlTelInput from 'intl-tel-input/build/js/intlTelInput';
import 'intl-tel-input/build/css/intlTelInput.css';
import { PhoneNumberUtil, PhoneNumberFormat, parsePhoneNumberFromString  } from 'google-libphonenumber';
import { CalendarDetails } from 'src/app/shared/models/calendarDetails';
import { SearchBudgetComponent } from 'src/app/shared/modal/search-budget/search-budget.component';



@Component({
  selector: 'app-add-new-events',
  templateUrl: './add-new-events.component.html',
  styleUrls: ['./add-new-events.component.css'],
 encapsulation: ViewEncapsulation.None
})
export class AddNewEventsComponent extends Subscriber implements OnInit {
  @ViewChild ('names') name: ElementRef;
  @ViewChild('messages', { read: ViewContainerRef }) showContainerRef: ViewContainerRef;
  @ViewChild('seriesevents', {read: ViewContainerRef}) seriesContainerRef: ViewContainerRef;
  @ViewChild('share', {read: ViewContainerRef}) shareContainerRef: ViewContainerRef;
  @ViewChild('customer', { read: ViewContainerRef }) searchcustomerContainerRef: ViewContainerRef;
  @ViewChild('phoneInput', { static: false }) phoneInput!: ElementRef;
  @ViewChild('budget', { read: ViewContainerRef }) searchbudgetContainerRef: ViewContainerRef;



  
  @Input() workPlaceId: any;
  @Input() workPlace: any;
  @Input() workPlaceName: any;
  @Input() calendar: any;
  @Input() idDetail: any;
  @Input() description: any;
  @Input() allDay:any;
  @Input() title:string;
  @Input() dateDefault: any;
  @Input() endDate: any;
  @Input() resourceId: any;  //ver si quitar este
  @Input() nameresourceId:any; // ver si quitar este
  @Input() calendarsNamesArr: Array<any>=[];
  @Input() resourceIds: Array<any>;
  @Input() repeatParams: any;
  @Input() startTimeWorkplace: any;
  @Input() currentDateRepeat: any;
  @Input() extendedProps: any;
  @Input() servicesIds: Array<any>;
  @Input() taskIds: string;
  @Input() customerInfo: any
  @Input() calendarDetails:CalendarDetails

  @Output() selectedIdCalendars =new EventEmitter<any>();
  @Output() description_event= new EventEmitter<any>();
  @Output() closeMeEvent = new EventEmitter();
  @Output() closeMeURLEvent = new EventEmitter();
  @Output() submitModal = new EventEmitter();
  @Output() deleteMeEvent = new EventEmitter();
  appendChild: any;



  constructor(private api: ApiService, 
    private globalVariables: GlobalVariables, 
    private utilsService: UtilsService,
    private http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef

   )  {
      super();
      
    }

  data: any ={};
  isOpenFrom: any = false;
  isOpenTo: any=false;
  isActiveFrom: any =false;
  isActiveTo: any = false;
  pipe =new DatePipe('ES');
  calendarsNamesSelectedArr: Array<any>=[];
  servicesNamesSelectedArr : Array<any>=[];
  taskNameSelected: string;
  dateTo: any='';
  dateFrom: any='';
  timeFrom: any;
  timeTo: any;
  message: string='';
  newEvents: Array<any>=[];
  tabIndex = 0;
  startDay:string='08:00:00';
  endDay:string='20:00:00';
  type:string="daily";
  repeat: boolean;
  show:boolean;
  objParams= new Recurrence();
  typeDelete: string='';
  typeUpdate: string='';
  calendarsNamesArrClone: Array<any>=[];
  duration: any ={};
  names: Array<any>=[]
  createdDate: any;
  createdTime: any;
  updatedDate: any;
  updatedTime: any;
  arrHours: Array<any>=[];
  arrHoursTo: Array<any>=[];
  arrHoursFrom: Array<any>=[];
  confirmWhatsapp: boolean;
  phone:any;
  newSelectArr: Array<any>=[];
  newSelectServicesArr : Array<any>=[];
  setOpen: boolean;
  setOpenService:boolean;
  servicesNamesArr: Array<any>=[]
  servicesNamesArrCopy: Array<any>=[]
  servicesNamesArrCloned: Array<any>=[];
  servicesNamesTBRArr=[];
  imagepath: string='';
  customerSel: any= {customer:{NOMCLTE: '', TELEFONO: '', EMAIL:'', REGISTRATION:'',REGISTRATIONS:null, idClient:'', budgets: null, budget:'', id_budget:'',reference:'', orFleets:null,orFleet:'' }};
  registration: any='';
  registrationNumber: any=''
  nameCustomer: any='';
  //phoneRegex?: any ='(.*)?';
  phoneRegex='\[34]|[0034]|[34]?(6|7)([0-9]{8})'
  registrationRegex='^([a-zA-Z]{1,3}[- ]?[0-9]{1,4}[- ]?[a-zA-Z]{1,3}|[0-9]{4}[- ]?[a-zA-Z]{3}|[a-zA-Z]{2}[- ]?[0-9]{3}[- ]?[a-zA-Z]{2})$'
  budgetRegex= /^D\d+_\d{2}\/\d+$/;
  existRegistrationNumber:boolean;
  error:any={message:'', type:''};
  service: boolean=true;
  serviceTBR: boolean;
  profitableServices:boolean
  task:boolean;
  arrExtrasServices: Array<any>;
  taskSelected: any={idTask:'',name:''};
  budget: any;
  countryCode:string='34';
  iti:any;
  initialCountryCode='es';
  phoneWorkPlace: string='';
  ignoreNextClick: boolean;
  reference:string='';
  inputRegistration:boolean;
  verifiedBudget: boolean;
  token: any;
  previousValue: any


 
  preventDrag() {
    return false;
  }


  ngOnInit(): void {


  console.log(this.calendarDetails,'calendarDetails')

  if(this.calendarDetails.customerInfo.customer.budget.ID)
  {
    this.searchBudget('',this.calendarDetails.customerInfo.customer.budget.ID)
  }

   this.initializeVariables()

   this.arrExtrasServices= this.globalVariables.extraServicesArr

   this.phoneWorkPlace =  this.globalVariables.getphoneWorkPlace()
    
   if (this.phoneWorkPlace==null)
      this.phoneWorkPlace= localStorage.getItem('phoneWorkPlace')
  
   this.api.service('/services','get').subscribe(res=>{
        res.categories.forEach((item, index)=>{
         // this.servicesNamesArr.push(item)
          this.servicesNamesArrCopy.push(JSON.parse(JSON.stringify(item)));
          item.services.forEach(val=>{
            this.servicesNamesArrCloned.push ({'idCategory': item.idCategory,'idService':val.idService})
          })
        })


    if(this.servicesIds) 
          {
            this.servicesNamesSelectedArr=this.servicesIds
            this.servicesIds.forEach(val=>{
              if (val.type=="AUTO-MOTO")
                {
                  this.serviceTBR= true
                  this.service   = false
                } 
              this.updateservicesNamesArr(val,'add')
            })
            
          }

    this.servicesNamesArrCopy.forEach(serv=>{
      let filteredRow: Array<any>
            filteredRow=serv.services.filter(row=>row.type!='AUTO-MOTO')
            if(filteredRow.length!=0)
            {
             this.servicesNamesArr.push({idCategory:serv.idCategory, minutes: serv.minutes,name: serv.name ,services: filteredRow})
            }
            
           })

    

    this.servicesNamesArrCopy.forEach(serv=>{
      let filteredRow: Array<any>
           filteredRow=serv.services.filter(row=>row.type=='AUTO-MOTO')
           if(filteredRow.length!=0)
           {
            this.servicesNamesTBRArr.push({idCategory:serv.idCategory, minutes: serv.minutes,name: serv.name ,services: filteredRow})
           }
           
          })

      })

    if(this.taskIds)
      {
        this.taskSelected.name= this.taskIds
        this.getTaskId()
        this.task       = true
        this.service    = false
        this.serviceTBR = false

      }

    if (Object.entries(this.customerInfo).length != 0)
     this.customerSel={customer:{NOMCLTE: this.customerInfo.customer.NOMCLTE, TELEFONO: this.customerInfo.customer.TELEFONO, EMAIL:this.customerInfo.customer.EMAIL, REGISTRATION:this.customerInfo.customer.REGISTRATION,REGISTRATIONS:this.customerInfo.customer.REGISTRATIONS, idClient:this.customerInfo.customer.idClient, budget: this.customerInfo.customer.budget.budget, id_budget:this.customerInfo.customer.budget.id_budget,budgets: this.customerInfo.customer.budgets, orFleet:this.customerInfo.customer.orFleet,reference: this.customerInfo.customer.reference,orFleets: this.customerInfo.customer.orFleets }}
    
    this.imagepath=this.globalVariables.getUrlImage()
   

    this.names                = this.calendarsNamesArr[0]
    this.arrHours             = this.globalVariables.arrHours
    this.startTimeWorkplace   = (getTimeNumber(this.startTimeWorkplace) + ":" +"00").toString()
    this.arrHoursFrom         = recalculateArrHours(this.startTimeWorkplace, this.arrHours)

    if (this.extendedProps!='')
    {
      if (this.extendedProps.createdDate)
      {
        this.createdDate        = transfromStringtoDate(parseDate(this.extendedProps.createdDate)) 
        this.createdTime        = parseDateToTime(parseDateTimeZ(this.extendedProps.createdDate))
      }

      if (this.extendedProps.updatedDate)
      {
        this.updatedDate        = transfromStringtoDate(parseDate(this.extendedProps.updatedDate))
        this.updatedTime        = parseDateToTime(parseDateTimeZ(this.extendedProps.updatedDate))
      }
      
    }
     
 
    if(this.dateDefault && !this.allDay && !this.endDate)
    {
      this.dateFrom           = parseDate(this.dateDefault);
      this.dateFrom           = transfromStringtoDate(this.dateFrom)
      this.dateTo             = parseDate(this.dateDefault);
      this.dateTo             = transfromStringtoDate(this.dateTo)
      this.timeFrom           = parseDateToTime(this.dateDefault);
      this.timeTo             = parseDateToTime(this.dateDefault);
      this.timeTo             = addHourToStr(this.timeTo,':')

    }

    else {if(this.dateDefault && this.allDay) {   
   
      this.dateFrom           = this.dateDefault + ' ' + this.startDay
      this.dateFrom           = transfromStringtoDate(this.dateFrom)
      this.dateTo             = this.dateDefault + ' ' + this.endDay
      this.dateTo             = transfromStringtoDate(this.dateTo)
      this.timeFrom           = this.startDay;
      this.timeTo             = this.endDay;
   
      }
       else {if(this.dateDefault && this.endDate)
        { 
          this.dateFrom       = parseDate(this.dateDefault);
          this.dateFrom       = transfromStringtoDate(this.dateFrom)
          this.dateTo         = parseDate(this.dateDefault);
          this.dateTo         = transfromStringtoDate(this.dateTo)
          this.timeFrom       = parseDateToTime(this.dateDefault);
          this.timeTo         = parseDateToTime(this.endDate);
        }}

    }
   
    this.duration= calculateTimeofRangeHours(this.timeFrom,this.timeTo)
    this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
    this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
    this.globalVariables.sethourFromEvent(this.timeFrom)
    this.globalVariables.sethourToEvent(this.timeTo)

    
    this.arrHoursTo= recalculateArrHours(this.timeFrom, this.arrHours)
   
    if (this.repeatParams)
    {
      this.initializeObjParams()
      if(this.repeatParams.repeat){
        this.repeat=true
        this.show=false
        this.type=this.repeatParams.repeatType
      }
      
    }
    else this.repeat=false
  
    if(this.resourceIds) this.calendarsNamesSelectedArr=this.resourceIds
     else if(this.nameresourceId){
        this.calendarsNamesSelectedArr.push({'idCalendar':this.resourceId,'name':this.nameresourceId})
      }

  
    this.deleteMatchedItemInNamesArr()
   
   
    this.selectedIdCalendars.emit(this.calendarsNamesSelectedArr)
    this.ngAfterViewInit();

  
  }

  ngAfterViewInit() {

    const title: HTMLElement = document.getElementById('title');
    title.focus();


    this.initializeIntlTelInput()

   
  }

  initializeVariables(){

    this.workPlaceId=this.calendarDetails.workPlaceId
    this.workPlace=this.calendarDetails.workPlace 
    this.workPlaceName=this.calendarDetails.workPlaceName
    this.calendar=this.calendarDetails.calendar
    this.idDetail=this.calendarDetails.idDetail
    this.description=this.calendarDetails.description
    this.allDay=this.calendarDetails.allDay
    this.title=this.calendarDetails.title
    this.endDate=this.calendarDetails.endDate
    this.resourceId=this.calendarDetails.resourceId
    this.nameresourceId=this.calendarDetails.nameresourceId
    this.calendarsNamesArr=this.calendarDetails.calendarsNamesArr
    this.resourceIds=this.calendarDetails.resourceIds
    this.repeatParams=this.calendarDetails.repeatParams
    this.startTimeWorkplace=this.calendarDetails.startTimeWorkplace
    this.currentDateRepeat=this.calendarDetails.currentDateRepeat
    this.extendedProps=this.calendarDetails.extendedProps
    this.servicesIds=this.calendarDetails.servicesIds
    this.taskIds=this.calendarDetails.taskIds
    this.customerInfo=this.calendarDetails.customerInfo
    this.dateDefault= this.calendarDetails.dateDefault


  }

  getCountryInfoPhoneNumber(telephone,initialCountryCode){


    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber= '+' + telephone

    try {
  
      const parsedNumber = phoneUtil.parse(phoneNumber);
      const countryCode = parsedNumber.getCountryCode();
      const regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber);
      this.initialCountryCode=regionCode? regionCode: initialCountryCode
      const formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      this.countryCode=countryCode
      let lenCountryCode= this.countryCode.toString().length
      let number= this.customerSel.customer.TELEFONO.substring(lenCountryCode)
      this.customerSel.customer.TELEFONO= number
      this.cdr.detectChanges();

     // console.log("Código de país:", countryCode);         // Ejemplo: 1 para EE.UU.
    //  console.log("Código de región:", regionCode);        // Ejemplo: US para EE.UU.
    //  console.log("Número formateado:", formattedNumber);  // Ejemplo: +1 415-555-2671

    } catch (error) {
      console.error("Número no válido:", error.message);
    }
   
   
   

   
  }

  updateCountryCode(){
    let lenCountryCode= this.countryCode.toString().length
    let number= this.customerSel.customer.TELEFONO.substring(lenCountryCode)
    const countryData = this.iti.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.customerSel.customer.TELEFONO= number
  }


  onKeydown(event: KeyboardEvent): void {
    if ( [event.code, event.key].includes('Enter') ) {
      this.saveEvent();
    }
  }

  saveEvent(): void{
    console.log(this.error,'error en el saveEvent')
    if(this.error.message==''){
     // this.error=''
      if(!this.repeat)
        this.clearObjParams()
  
      if (this.customerSel)
      {
         if (!this.customerSel.customer.idClient || this.customerSel.customer.idClient=='')
         {
              if (!this.task)
              {
                if (validatePhone(this.customerSel.customer.TELEFONO) && validateCustomer(this.customerSel.customer.NOMCLTE))
                  this.saveCustomerDB()
                else this.error={message:'Debe completar o revisar formato de campos requeridos', type:'phone'}
              }
              else{
                if(this.idDetail){
                  this.editEvent();
                } 
                
                else this.saveNewEvent()
              }  
         }
         else{
              if (this.customerSel.customer.REGISTRATION) {
                if (validateRegistration(this.customerSel.customer.REGISTRATION, this.registrationRegex)){
                  this.checkRegistrationNumber(this.customerSel.customer.idClient)
                  console.log('despues del checkregistration')
                  if(this.idDetail){
                      this.editEvent();
                  } 
                  else {
                    this.saveNewEvent(this.customerSel.customer.idClient)}
                }
                else
                  this.error = {message :'El formato de la matrícula no es válida', type:'registration'}
   
              }
              else {
                if(this.idDetail){
                    this.editEvent();
                } 
                else {
                  this.saveNewEvent(this.customerSel.customer.idClient)}
                
              }                
         } 
            
      }
    }
   
   
  }

  setActive(value1?: boolean, value2?: boolean){  

 
    if(value1)
      { this.isActiveFrom=value1;
        this.isActiveTo=false
       }
    
    if(value2)
      { this.isActiveTo=value2;
        this.isActiveFrom=false
      
      }

      this.ignoreNextClick=true

  
  }

  setCloseModal(event){

    if (!this.ignoreNextClick){
      
      this.isActiveFrom=false
      this.isActiveTo= false

    }

    this.ignoreNextClick=false

  }




  initializeObjParams(){
    this.objParams.repeat                = this.repeatParams.repeat,
    this.objParams.type                  = this.repeatParams.repeatType,
    this.objParams.repeatAlways          = this.repeatParams.repeatAlways,
    this.objParams.repeatDuring          = this.repeatParams.repeatDuring,
    this.objParams.repeatEvery           = this.repeatParams.repeatEvery,
    this.objParams.repeatUntil           = this.repeatParams.repeatUntil,
    this.objParams.repeatWeekdayPosition = this.repeatParams.repeatWeekdayPosition,
    this.objParams.repeatWeeklyFriday    = this.repeatParams.repeatWeeklyFriday,
    this.objParams.repeatWeeklyMonday    = this.repeatParams.repeatWeeklyMonday,
    this.objParams.repeatWeeklySaturday  = this.repeatParams.repeatWeeklySaturday,
    this.objParams.repeatWeeklySunday    = this.repeatParams.repeatWeeklySunday,
    this.objParams.repeatWeeklyThursday  = this.repeatParams.repeatWeeklyThursday,
    this.objParams.repeatWeeklyTuesday   = this.repeatParams.repeatWeeklyTuesday,
    this.objParams.repeatWeeklyWednesday = this.repeatParams.repeatWeeklyWednesday
  }

  clearObjParams(){
    this.objParams.repeat                = false,
    this.objParams.type                  = '',
    this.objParams.repeatAlways          = false,
    this.objParams.repeatDuring          = 0,
    this.objParams.repeatEvery           = 0,
    this.objParams.repeatUntil           = undefined,
    this.objParams.repeatWeekdayPosition = 0,
    this.objParams.repeatWeeklyFriday    = false,
    this.objParams.repeatWeeklyMonday    = false,
    this.objParams.repeatWeeklySaturday  = false,
    this.objParams.repeatWeeklySunday    = false,
    this.objParams.repeatWeeklyThursday  = false,
    this.objParams.repeatWeeklyTuesday   = false,
    this.objParams.repeatWeeklyWednesday = false
  }


setTime( isOpenFrom:boolean, isOpenTo:boolean)
  { 
    
    if(isOpenTo)
     { 
      this.globalVariables.sethourToEvent(this.timeTo)
      isOpenTo=false}
    
     if(isOpenFrom)

     {
      this.arrHoursTo= recalculateArrHours(this.timeFrom, this.arrHours)
      this.timeTo= (addHourToStr(this.timeFrom,':', this.duration))
      this.globalVariables.sethourFromEvent(this.timeFrom)
      this.globalVariables.sethourToEvent(this.timeTo)
      isOpenFrom=false}

}

setAllDay(e:any,value:boolean){
 
}

initializeCustomerSel(){
  this.customerSel= {customer:{NOMCLTE: '', TELEFONO: '', EMAIL:'', REGISTRATION:'', REGISTRATIONS:[], idClient:''}};
}



createArrCalendarsName(){
    this.calendar.forEach(calendar => {
      const {idCalendar, name, shortName} = calendar;
      this.calendarsNamesArr.push({'name': (name? name:shortName), 'idCalendar': idCalendar}) 
    });

  
    return this.calendarsNamesArr;
    
}

createCalendarsNameArrClone(){
    this.calendarsNamesArr.forEach(calendar => {
      const {idCalendar, name, shortName} = calendar;
      this.calendarsNamesArrClone.push({'name': (name? name:shortName), 'idCalendar': idCalendar}) 
    });

    return this.calendarsNamesArrClone;
    
}


_handleTab(event: KeyboardEvent) {
    if (event.code === 'Tab') { 
         if (event.shiftKey) { 
              document.getElementById('input3').focus();
            }
         }
}

clickDateSelected(event){

     if(this.error.message!='' && this.error.type=='date') this.error={message:'',type:''}

     if(this.isActiveTo){
        this.dateTo= transfromStringtoDate(event)
        this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
        this.isActiveTo=false
      }
      
     if(this.isActiveFrom){
       this.dateFrom= transfromStringtoDate(event)
         this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
       if (this.dateDefault!='')
         {
           this.dateTo= transfromStringtoDate(event)
           this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
           this.dateDefault=''
         }
 
         this.isActiveFrom=false
      }              
}

checkActiveDatesFromTo(){
  if (this.isActiveTo)
    this.isActiveTo=false
  if (this.isActiveFrom)
    this.isActiveFrom=false
}

  
getParams(event){

  this.objParams=event
  if(this.objParams.repeat)
  {
    this.repeat=true
    this.show=false
    this.dateTo=this.dateFrom
  }

}


goToRecurrence(){
  this.repeat=true

  if(this.show) this.show=false
  else this.show=true
}



changeRepeatCheck(){
  this.repeat=!this.repeat

  if(!this.repeat)
    this.show=false
  else this.show=!this.show

}

sendMessage(){

  document.addEventListener('click', function(e) {
    if(document.getElementById('share-1') !=null)
    {
      if (document.getElementById('share').contains(e.target as Node)) {
        document.getElementById('share-1').style.display="block"
      } 
      else
        document.getElementById('share-1').style.display="none"
    }
  })

}

sendWhatsapp(){

  var srv: any=''
  if (this.customerSel.customer.TELEFONO)
  {
    if (this.customerSel.customer.TELEFONO.length<10)
       this.customerSel.customer.TELEFONO= this.countryCode +  this.customerSel.customer.TELEFONO

    if (validatePhone(this.customerSel.customer.TELEFONO, this.phoneRegex)){

      this.servicesNamesSelectedArr.forEach(serv=>{
        if (srv!='')
        srv =  serv.name + ','+ srv 
        else
        srv= serv.name
      })

      const message= `Confirmamos su cita: 
      *Día*: ${this.dateFrom}         
      *Hora*: ${this.timeFrom}. 
      *Taller*: ${this.workPlaceName}. 
      *Direccion*: ${this.workPlace.address}. 
      *Teléfono*: ${this.phoneWorkPlace}.
      *Matrícula*:${this.customerSel.customer.REGISTRATION}. 
      *Servicios*: ${srv}.
      *Nro. Fleet*: ${this.customerInfo.customer.orFleet}`

      //const data={'message':message,'phone':this.customerSel.customer.TELEFONO, 'profile':'TEST1', 'type':'text'}
      const data={'message':message,'to_number':this.customerSel.customer.TELEFONO,'type':'text'}
      
       this.api.service('/sendMessage', 'post', data,"whatsapp").subscribe(res=>(
        console.log(res,'enviado mensaje whatsapp')))
     // this.api.service('/message', 'post', data,"whatsapp").subscribe(res=>(
     // console.log(res,'enviado mensaje whatsapp')))
      this.openBottomSheet({'title': `<div> Mensaje enviado con éxito </div>`,'type_actions':'close','succeed':'true' },{})
    }
    else
      this.error={type:'phone', message:'Debe tener un número de teléfono válido para enviar mensaje'}

  }
  else
  {
      this.error={type:'phone', message:'Debe tener un número de teléfono válido para enviar mensaje'}
  }
    
}



openBottomSheet(data:any, data_event: any, idDetail?:any,start?:any,end?:any) {
  this.bottomSheet.open(BottomSheetComponent, {
    data: data
  });
 
}



saveNewEvent(idClient?:any){

  console.log('entra en el savenwevent')

  let idServices=this.createIdServices()

  if(this.allDay){
      this.dateFrom = parseDate(this.dateDefault) + ' ' + this.startDay
      this.dateFrom = transfromStringtoDate(this.dateFrom)
      this.dateTo   = parseDate(this.dateDefault) + ' ' + this.endDay
      this.dateTo   = transfromStringtoDate(this.dateTo)
      this.timeFrom = this.startDay;
      this.timeTo   = this.endDay;
      this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
      this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
      this.globalVariables.sethourFromEvent(this.timeFrom)
      this.globalVariables.sethourToEvent(this.timeTo)
  }

  let dateTimeFrom = this.globalVariables.dateFromEvent + ' '+this.globalVariables.hourFromEvent 
  let dateTimeTo   = this.globalVariables.dateToEvent+' ' + this.globalVariables.hourToEvent
  var idCalendars: Array<any>=[]

  if(this.calendarsNamesSelectedArr && this.calendarsNamesSelectedArr.length>0){
       for (let i=0; i<this.calendarsNamesSelectedArr.length; i++)
        {
          idCalendars.push(this.calendarsNamesSelectedArr[i].idCalendar) 
        }

        this.error= validateFields (this.globalVariables.dateFromEvent, this.globalVariables.dateToEvent, this.title, this.calendarsNamesSelectedArr, this.customerSel.customer.TELEFONO,this.customerSel.customer.NOMCLTE, this.task, this.servicesNamesSelectedArr.length>0? this.servicesNamesSelectedArr: this.taskSelected.name )
        if (this.error.message==''){
            this.data={
              'idCalendars':idCalendars,
              'idServices':idServices,
              'idClient' :idClient?idClient:'',
              'idTask'   : this.task?this.taskSelected.idTask:'',
              'email'    :this.customerSel.customer.EMAIL?this.customerSel.customer.EMAIL:'',
              'title': this.title,
              'dateTimeFrom':dateTimeFrom,
              'dateTimeTo':dateTimeTo,
              'description':this.description,
              'repeat':this.objParams.repeat,
              'repeatType': this.objParams.type,
              'repeatAlways': this.objParams.repeatAlways,
              'repeatUntil': this.objParams.repeatUntil,
              'repeatEvery': this.objParams.repeatEvery,
              'repeatDuring': this.objParams.repeatDuring,
              'repeatWeeklyMonday': this.objParams.repeatWeeklyMonday,
              'repeatWeeklyTuesday': this.objParams.repeatWeeklyTuesday,
              'repeatWeeklyWednesday': this.objParams.repeatWeeklyWednesday,
              'repeatWeeklyThursday': this.objParams.repeatWeeklyThursday,
              'repeatWeeklyFriday': this.objParams.repeatWeeklyFriday,
              'repeatWeeklySaturday': this.objParams.repeatWeeklySaturday,
              'repeatWeeklySunday': this.objParams.repeatWeeklySunday,
              'repeatWeekdayPosition':this.objParams.repeatWeekdayPosition,
              'registrationNumber': this.customerSel.customer.REGISTRATION,
              'budget'             : this.customerSel.customer.budget,
              'id_budget'          : this.customerSel.customer.id_budget,
              'orFleet'            : this.customerSel.customer.orFleet,
              'reference'          : this.customerSel.customer.reference
          }

          this.api.service('/addCalendarDetail','post',this.data).subscribe(res=>{
            if(res.msg='OK'){
              var calendars =[]
              res.calendars.forEach((calendar) => {
                const {idCalendar, name, shortName} = calendar;
                 calendars.push({ calendar :{
                  idCalendar:idCalendar, 
                  shortName: shortName,
                  name:name
                }
                });})

              this.data={'idCalendar':idCalendars,
              'idCalendarDetail':res.calendarDetail.idCalendarDetail,
              'title': res.calendarDetail.title,
              'dateFrom':res.calendarDetail.dateFrom,
              'dateTo':res.calendarDetail.dateTo, 
              'description':res.calendarDetail.description,
              'calendars': calendars,
              'dateFromModal': res.calendarDetail.dateFrom,
              'dateToModal': res.calendarDetail.dateTo,
              repeatParams: {
                repeat                :  res.calendarDetail.repeat,
                repeatAlways          :  res.calendarDetail.repeatAlways,
                repeatDuring          :  res.calendarDetail.repeatDuring,
                repeatEvery           :  res.calendarDetail.repeatEvery,
                repeatType            :  res.calendarDetail.repeatType,
                repeatUntil           :  res.calendarDetail.repeatUntil,
                repeatWeekdayPosition :  res.calendarDetail.repeatWeekdayPosition,
                repeatWeeklyFriday    :  res.calendarDetail.repeatWeeklyFriday,
                repeatWeeklyMonday    :  res.calendarDetail.repeatWeeklyMonday,
                repeatWeeklySaturday  :  res.calendarDetail.repeatWeeklySaturday,
                repeatWeeklySunday    :  res.calendarDetail.repeatWeeklySunday,
                repeatWeeklyThursday  :  res.calendarDetail.repeatWeeklyThursday,
                repeatWeeklyTuesday   :  res.calendarDetail.repeatWeeklyTuesday,
                repeatWeeklyWednesday :  res.calendarDetail.repeatWeeklyWednesday
               }
              }
  
              this.submitModal.emit({ result: "aceptar", data: this.data});
            }
          })
        }
 }
 else 
    {this.error={message:'Debe seleccionar al menos un operador', type:'calendars'}}
  
}

updateEvent(){
console.log('entra al updateevent')
console.log(this.error,'error desde el update event')
  var idCalendars: Array<any>=[]
  if(this.allDay){
    this.dateFrom  = parseDate(this.dateDefault) + ' ' + this.startDay
    this.dateFrom  = transfromStringtoDate(this.dateFrom)
    this.dateTo    = parseDate(this.dateDefault) + ' ' + this.endDay
    this.dateTo    = transfromStringtoDate(this.dateTo)
    this.timeFrom  = this.startDay;
    this.timeTo    = this.endDay;
    this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
    this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
    this.globalVariables.sethourFromEvent(this.timeFrom)
    this.globalVariables.sethourToEvent(this.timeTo)
}

  let dateTimeFrom = this.globalVariables.dateFromEvent + ' '+this.globalVariables.hourFromEvent 
  let dateTimeTo   = this.globalVariables.dateToEvent+' ' + this.globalVariables.hourToEvent

  if(this.calendarsNamesSelectedArr.length)
    idCalendars=this.getIdCalendars(this.calendarsNamesSelectedArr)

  let idServices=this.createIdServices()

  if (this.error.message=='')
      this.error= validateFields (this.globalVariables.dateFromEvent, this.globalVariables.dateToEvent, this.title, this.calendarsNamesSelectedArr, this.customerSel.customer.TELEFONO,this.customerSel.customer.NOMCLTE, this.task, !this.task? this.servicesNamesSelectedArr: this.taskSelected.name )
  if(this.error.message==''){
    this.updateAssignedResources({
      'idCalendars':idCalendars,
      'idServices':!this.task?idServices:null,
      'idClient'  :this.customerSel && !this.task?this.customerSel.customer.idClient:null,
      'title': this.title,
      'idTask': this.task?this.taskSelected.idTask:null,
      'dateTimeFrom':dateTimeFrom,
      'dateTimeTo':dateTimeTo,
      'description':this.description,
      'repeat':this.objParams.repeat,
      'repeatType': this.objParams.type,
      'repeatAlways': this.objParams.repeatAlways,
      'repeatUntil': this.objParams.repeatUntil,
      'repeatEvery': this.objParams.repeatEvery,
      'repeatDuring': this.objParams.repeatDuring,
      'repeatWeeklyMonday': this.objParams.repeatWeeklyMonday,
      'repeatWeeklyTuesday': this.objParams.repeatWeeklyTuesday,
      'repeatWeeklyWednesday': this.objParams.repeatWeeklyWednesday,
      'repeatWeeklyThursday': this.objParams.repeatWeeklyThursday,
      'repeatWeeklyFriday': this.objParams.repeatWeeklyFriday,
      'repeatWeeklySaturday': this.objParams.repeatWeeklySaturday,
      'repeatWeeklySunday': this.objParams.repeatWeeklySunday,
      'repeatWeekdayPosition':this.objParams.repeatWeekdayPosition,
      'option': this.typeUpdate,
      'date' :  this.currentDateRepeat? parseDate(this.currentDateRepeat):'',
      'registrationNumber': this.customerSel.customer.REGISTRATION,
      'budget': this.customerSel.customer.budget,
      'id_budget' : this.customerSel.customer.id_budget,
      'orFleet': this.customerSel.customer.orFleet,
      'reference' : this.customerSel.customer.reference
  
    })
  }
 
}


editEvent(){

  console.log('entra al edit event')
  console.log(this.error,'error desde el edit')

  if (this.task)
   this.customerSel= {customer:{NOMCLTE: '', TELEFONO: '', EMAIL:'', REGISTRATION:'', REGISTRATIONS:[], idClient:'', budgets: [], budget:'', reference:'',orFleets:[], orFleet:'', id_budget:''}};
  this.typeUpdate=''
  if(this.repeatParams.repeat)
    this.updateSeriesEvent()
  else
    this.updateEvent()

}

validateForm(dateFrom: any, dateTo: any, field:any, arr: Array<any>)
{
  let validateDateOK= validateDatesEN(dateFrom, dateTo,'-')
    if(!validateDateOK)
      this.message ='La fecha/ hora Desde no puede ser mayor que la fecha/hora Hasta'
  
    else {
      this.message=''
      let validateTitleOK= validateTitle(field)
        if (!validateTitleOK)
        { this.message='El campo Título no puede estar en blanco'}
        else{
          this.message=''
          let validateCalendarioOK= validateCalendario(arr)
            if(!validateCalendarioOK)
             {this.message='El campo Calendario no puede estar en blanco'}
              else{
                this.message=''
              }
        }
    }
}


updateAssignedResources(data: any){
  var result: any={}
  if(this.idDetail)
  {
    if (data)
    {
      this.api.service('/calendarDetail/'+this.idDetail,'put',data).subscribe(res=>{
        if(res.msg='OK')
          {   var calendars =[] 
              res.calendars.forEach((calendar) => {
              const {idCalendar, name, shortName} = calendar;
               calendars.push({ calendar :{
                idCalendar:idCalendar, 
                shortName: shortName,
                name:name
              }
              });})

             /* this.newEvents.push({'idCalendarDetail':Number(this.idDetail),
              'idCalendar': calendars,
              'title': this.title,
              'dateFrom': transfromDateTimeENT(this.dateFrom,this.timeFrom),
              'dateTo': transfromDateTimeENT(this.dateTo,this.timeTo),
              'description': this.description,
              })*/
             

              result= {'idCalendarDetail':Number(this.idDetail),
              'idCalendar'    : calendars,
              'title'         : this.title,
              'dateFrom'      : transfromDateTimeENT(this.dateFrom,this.timeFrom),
              'dateTo'        : transfromDateTimeENT(this.dateTo,this.timeTo),
              'description'   : this.description,
              'dateFromModal' : res.calendarDetail.dateFrom,
              'dateToModal'   : res.calendarDetail.dateTo,
              'repeatParams'  : {
                repeat                :  res.calendarDetail.repeat,
                repeatAlways          :  res.calendarDetail.repeatAlways,
                repeatDuring          :  res.calendarDetail.repeatDuring,
                repeatEvery           :  res.calendarDetail.repeatEvery,
                repeatType            :  res.calendarDetail.repeatType,
                repeatUntil           :  res.calendarDetail.repeatUntil,
                repeatWeekdayPosition :  res.calendarDetail.repeatWeekdayPosition,
                repeatWeeklyFriday    :  res.calendarDetail.repeatWeeklyFriday,
                repeatWeeklyMonday    :  res.calendarDetail.repeatWeeklyMonday,
                repeatWeeklySaturday  :  res.calendarDetail.repeatWeeklySaturday,
                repeatWeeklySunday    :  res.calendarDetail.repeatWeeklySunday,
                repeatWeeklyThursday  :  res.calendarDetail.repeatWeeklyThursday,
                repeatWeeklyTuesday   :  res.calendarDetail.repeatWeeklyTuesday,
                repeatWeeklyWednesday :  res.calendarDetail.repeatWeeklyWednesday
               },
               'option'            : this.typeUpdate,
               'registrationNumber': res.registrationNumber
              }
            this.submitModal.emit({ result: "aceptar", data: result})        
          }
        })
    }
  }
} 

 
  deleteSeriesEvent(val?:boolean)
  { 
    const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'delete-series','Aplicar únicamente a este evento','Aplicar a todos los eventos de la serie.','Aplicar a este evento y a los siguientes.')
    modalRefseries.subscribe(res=>{
      if(res)
      { switch(res["result"]){
        case "delete-series":
          switch(res["type"]){
            case "allEvents":
              this.typeDelete='allEvents'
              break;
            case "onlyThisEvent":
              this.typeDelete='onlyThisEvent'
              break;
            case "thisEventAndLater":
              this.typeDelete='thisEventAndLater'
              break;
          }
          break;
        case "cancel":
          this.typeDelete=''
         break;
      }}
      if (this.typeDelete!='')
        {
          var data={
            option: this.typeDelete,
            date  : parseDate(this.currentDateRepeat)
          }
          //this.api.deleteSeriesEvent(this.idDetail,data)
          this.api.service('/calendarDetail/'+this.idDetail,'delete',data).subscribe(res=>{
            if(res.msg='OK')
                    { 
                        if(val)
                        {
                          this.globalVariables.setresouserselected(0)
                        } 

                    }
                    
                      this.deleteMeEvent.emit({ result: "eliminar", data: this.idDetail})
                    }
           )
        }
   
    })
   
  }

  updateSeriesEvent(val?:boolean)
  { 
    const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'update-series','Aplicar únicamente a este evento','Aplicar a todos los eventos de la serie.','Aplicar a este evento y a los siguientes.')
    modalRefseries.subscribe(res=>{
      if(res)
      { switch(res["result"]){
        case "update-series":
          switch(res["type"]){
            case "allEvents":
              this.typeUpdate='allEvents'
              break;
            case "onlyThisEvent":
              this.typeUpdate='onlyThisEvent'
              break;
            case "thisEventAndLater":
              this.typeUpdate='thisEventAndLater'
              break;
          }
          break;
        case "cancel":
          this.typeUpdate=''
         break;
      }
      if (this.typeUpdate)
       this.updateEvent()}
    })
   
  }

  deleteEvent(val?: boolean){
    this.typeDelete=''
    if(this.repeatParams.repeat)
    {
      this.deleteSeriesEvent(val)
    }
    else{
      const modalRefmessage=this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,'¿Estas seguro que desea eliminar el evento?', 'delete')
      modalRefmessage.subscribe(res=>{
      if(res){
        switch(res["result"]){
          case "eliminar":
            { 
              var data={
                option: '',
                date  : ''
              }
              this.api.service('/calendarDetail/'+ this.idDetail,'delete',data).subscribe(res=>{
                if(res.msg='OK')
                         { 
                            if(val)
                            {
                              this.globalVariables.setresouserselected(0)
                            } 
          
                         }
                          else 
                          { console.log('error eliminando evento ') }
                this.deleteMeEvent.emit({ result: "eliminar", data: this.idDetail})
              }
              )
              break;
            }
          case "cancelar":
           {
            this.closeMeEvent.emit()
            break;
              }
          }}})
      }
   
  }

 
  getIdCalendars(arrCalendars:Array<any>): Array<any>{
    var idCalendars: Array<any>=[]
      for (let i=0; i<arrCalendars.length; i++ )
      {
        idCalendars.push(Number(arrCalendars[i].idCalendar))
      }
    return idCalendars
   }

  closeEvent(event){
    this.closeMeEvent.emit()
  }

  changeTab(event: { index: number; }){
    this.tabIndex = event.index;
    switch (this.tabIndex){
      case 0:
        this.type="daily";
        break;
      case 1:
        this.type="weekly";
        break;
      case 2:
        this.type="monthly";
        break;
      case 3:
        this.type="annually"
        break;
      default:
        this.type="daily"
        break;

    }

  }


  addCalendarsOptions(){
      this.setOpen=true;
      this.newSelectArr.push({id:this.newSelectArr.length, value:''})
  }

  deleteCalendarsOptions(value){

    this.updatecalendarsNamesArr(value,'del')
    this.calendarsNamesSelectedArr.forEach((element,indx) => {
      if (element.idCalendar==value.idCalendar)
        this.calendarsNamesSelectedArr.splice(indx,1)
      
    });

}

  updatecalendarsNamesSelectedArr(){

    this.newSelectArr.forEach(element => {
      this.calendarsNamesSelectedArr.push(element.value)
      
    });
    this.newSelectArr=[]
      
  }

  updatecalendarsNamesArr(item:any, option:string){
    let indx: number
    switch (option){
      case 'del':
          this.calendarsNamesArr.push(item)
        break;
      case 'add':
         if((indx= this.calendarsNamesArr.findIndex(element=>element.idCalendar==item.idCalendar))!=-1)
            this.calendarsNamesArr.splice(indx,1)
        break;
    }

  }

  deleteMatchedItemInNamesArr(){
  
      this.calendarsNamesSelectedArr.forEach((item,indx)=>{
      
            if((indx= this.calendarsNamesArr.findIndex(element=>element.idCalendar==item.idCalendar))!=-1)
            {
             this.calendarsNamesArr.splice(indx,1)
            }
           
        })


      this.servicesNamesSelectedArr.forEach((item,indx)=>{
          if((indx= this.servicesNamesArr.findIndex(element=>element.idCategory==item.idCategory))!=-1)
          {
           this.servicesNamesArr.splice(indx,1)
          }

       })
     
  }

  getValueSel(event){
    this.newSelectArr=event
    this.updatecalendarsNamesSelectedArr()
    if(this.error.message!='' && this.error.type=='calendars') this.error={message:'',type:''}
    if (event.length>0)
      this.updatecalendarsNamesArr(event[0].value,'add')
     this.setOpen=false

  }

  addServicesOptions(){

    this.setOpenService=true;
    this.newSelectServicesArr.push({id:this.newSelectServicesArr.length, value:''})
  }

  deleteServicesOptions(value){

    this.servicesNamesSelectedArr.forEach((element,indx) => {
        if (element.idService==value.idService)
          this.servicesNamesSelectedArr.splice(indx,1)
        
      });
      this.updateservicesNamesArr(value,'del')
      this.createTitle(this.servicesNamesSelectedArr,  this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )

  }

  updateservicesNamesSelectedArr(){

    this.newSelectServicesArr.forEach(element => {
      this.servicesNamesSelectedArr.push({idService:element.value.idService,name:element.value.name})
      
    });
    this.newSelectServicesArr=[]
    
  }

  updateservicesNamesArrorg(item:any, option:string){
    let indx: number
    switch (option){
      case 'del':
        let idCategory= this.getCategoryService(item.idService)
        this.servicesNamesArr.forEach((val,i)=>{
          if(val.idCategory==idCategory)
          {
            if((indx= val.services.findIndex(element=>element.idService==item.idService))==-1)
            {
              val.services.push(item)
              return
            }
            
          }       
        })
        break;
      case 'add':
        this.servicesNamesArr.forEach((val,i)=>{
          if((indx= val.services.findIndex(element=>element.idService==item.idService))!=-1)
          {
            this.servicesNamesArr[i].services.splice(indx,1)
          }
        })
        break;
    }

  }

  updateservicesNamesArr(item:any, option:string){
   let indx: number
   let arr: Array<any>=[]
   if (this.service)
    arr= this.servicesNamesArr
   else
    arr= this.servicesNamesTBRArr

    switch (option){
      case 'del':
        let idCategory= this.getCategoryService(item.idService)
        arr.forEach((val,i)=>{
          if(val.idCategory==idCategory)
          {
            if((indx= val.services.findIndex(element=>element.idService==item.idService))==-1)
            {
              val.services.push(item)
              return
            }
            
          }       
        })
        break;
      case 'add':
        arr.forEach((val,i)=>{
          if((indx= val.services.findIndex(element=>element.idService==item.idService))!=-1)
          {
            arr[i].services.splice(indx,1)
          }
        })
        break;
    }

  }

  getValueServiceSel(event){
    this.newSelectServicesArr=event
    this.updateservicesNamesSelectedArr()
    if (this.error.type=="services" && this.servicesNamesSelectedArr.length>0) this.error={message:'', type:''}
    if (event.length>0)
      this.updateservicesNamesArr(event[0].value,'add')
    this.setOpenService=false
    this.createTitle(this.servicesNamesSelectedArr,  this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
  }



  createIdServices(){
    let arreglo: Array<any>=[]
    this.servicesNamesSelectedArr.forEach(item=>{
      arreglo.push(item.idService)
    })

    return arreglo
  }

  getCategoryService(val: any){
  let category: any
    this.servicesNamesArrCloned.forEach((item)=>{
      if((item.idService==val))
          {
            category=item.idCategory
            return category
          }
      
    })

    return category
    
  }

  searchCustomer(field?: any, value?: any){
    const modalRefcustomer=this.utilsService.openModalSearchCustomer (SearchCustomerComponent, this.searchcustomerContainerRef, {field: field, value: value},this.workPlace?this.workPlace.enterprise:'1')
    modalRefcustomer.subscribe(res=>{
      if (res.customer.NOMCLTE){
        this.customerSel.customer.NOMCLTE      = res.customer.NOMCLTE
        this.customerSel.customer.REGISTRATION = res.customer.REGISTRATION
        this.customerSel.customer.TELEFONO     = res.customer.TELEFONO
        this.customerSel.customer.EMAIL        = ''
        this.customerSel.customer.REGISTRATIONS =[]
        this.customerSel.customer.idClient      =''
        this.createTitle(this.servicesNamesSelectedArr,this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
        this.getCountryInfoPhoneNumber(this.customerSel.customer.TELEFONO,this.initialCountryCode)
      }

    })
    
  }

  searchBudget(budget?: any, id?:any){
    let document:any=''
    if (budget) document=budget
    else document=id
    if (id)
    {
      this.api.getTokenGTS().subscribe(res=>{
      this.token=res.value
      this.searchBudgets(document,this.workPlace?this.workPlace.enterprise :'01', id?true:false)
    })
    }
    else{
      const modalRefbudget=this.utilsService.openModalSearchBudget (SearchBudgetComponent, this.searchbudgetContainerRef, {field: this.customerSel.customer.budget?this.customerSel.customer.budget:'', id: this.customerSel.customer.id_budget?this.customerSel.customer.id_budget:''},this.workPlace?this.workPlace.enterprise:'01')
      modalRefbudget.subscribe(res=>{
        console.log(res,'res del search budget')
        //this.verifiedBudget=true
        this.error={message:'', type:''}
        this.customerSel.customer.id_budget= res.budget.id_budget
        this.customerSel.customer.budget= res.budget.budget
  
       /*if (res.customer.NOMCLTE){
          this.customerSel.customer.NOMCLTE      = res.customer.NOMCLTE
          this.customerSel.customer.REGISTRATION = res.customer.REGISTRATION
          this.customerSel.customer.TELEFONO     = res.customer.TELEFONO
          this.customerSel.customer.EMAIL        = ''
          this.customerSel.customer.REGISTRATIONS =[]
          this.customerSel.customer.idClient      =''*/
         this.createTitle(this.servicesNamesSelectedArr,this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
      
        })
    }   
  }

  searchBudgets(document:any, enterprise:any, id:boolean){
    let data_budget: any
    let context_budget: any

    if (enterprise.length<2)
      enterprise= '0' + enterprise.toString()

    context_budget = `[{"ENTERPRISE_CODE":"${enterprise}"}]`

    if(!id){
        data_budget=`[{\"SEARCH_TEXT\":"${document}" ,\"ROWID_DIRECTO\":\"\"}]`
    }
    else
    {
        data_budget=`[{\"SEARCH_TEXT\":\"\" ,\"ROWID_DIRECTO\":"${document}"}]`
    
    }
 
    this.api.budgets(this.token,data_budget,context_budget).subscribe(res=>{
          if(res.value.Rows.length>0){
            res.value.Rows.forEach(row=>{
              if (row.NUM_PRESUPUESTO)
                {
                  //this.verifiedBudget=true
                  this.error={message:'', type:''}
                  this.customerSel.customer.id_budget= row._ROWID
                  this.customerSel.customer.budget= row.NUM_PRESUPUESTO
               
                }
               
              else
                this.error={message:'Presupuesto inválido', type:'budget'}
               
            })
          }
          else
          this.error={message:'Presupuesto inválido', type:'budget'}
        })
  }

  closeMessageError(){
    this.customerSel.customer.budget=''
    this.error={message:'',type:''}
  }



  getCustomerByPhone(event?){
    let registrationNumbers: Array<any>=[]
    if (this.customerSel.customer.TELEFONO){
      const phone=  this.customerSel.customer.TELEFONO.replace(/[-\s]/g,'')
      if (validatePhone(phone)){
        this.api.service('/checkPhone/'+ this.countryCode + phone,'get').subscribe(res=>{
          if (res)
              if (res.client!=null){
                this.api.service('/registrationNumbersByClient/'+res.client.idClient,'get').subscribe(registration=>{
                  registration.registration.forEach(num=>{
                    registrationNumbers.push(num.registrationNumber)
                  })
                  this.customerSel={customer:{NOMCLTE: res.client.firstName + ' ' + res.client.lastName, TELEFONO: res.client.phone.substring(this.countryCode.length), EMAIL:res.client.email,REGISTRATION:registrationNumbers[0], REGISTRATIONS:registrationNumbers, idClient:res.client.idClient, budget: this.customerSel.customer.budget? this.customerSel.customer.budget:'', id_budget: this.customerSel.customer.id_budget? this.customerSel.customer.id_budget:'' }}
                  this.createTitle(this.servicesNamesSelectedArr,this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
                  this.registration=this.customerSel.customer.REGISTRATIONS[0]
                  if(this.error.message!='' && this.error.type=="phone")
                    this.error={message:'',type:''}
                  
                })
              }
        })
      }
      else this.message= 'Número de teléfono no válido'
    
    }
  }



  checkRegistrationNumber(idClient?:number){
    if (validateRegistration(this.customerSel.customer.REGISTRATION, this.registrationRegex)){
      this.api.service('/checkRegistrationNumber/'+ idClient + '/' + this.customerSel.customer.REGISTRATION,'get').subscribe(res=>{
        if (res.clientRegistrationNumber.length==0)
        {
            this.api.service('/addRegistrationNumber','post',{idClient: idClient,registrationNumber:this.customerSel.customer.REGISTRATION}).subscribe(val=>{
             
            })
        }
        else console.log('la matrícula ya existe')
          
      })}
    else
        this.error = {message :'El formato de la matrícula no es válida', type:'registration'}
    
  }


  saveCustomerDB()
  { 
    console.log('entra al SaveCustomerDB')
    let idClient: any
    let urlCheckPhone: any =''
    var nameFormatted: string=''
    nameFormatted= this.customerSel.customer.NOMCLTE.trim()

    let pos = nameFormatted.indexOf(" ");

    const name=pos!==-1?nameFormatted.substring(0,pos):nameFormatted
    const lastName= pos!==-1?nameFormatted.substring(pos+1):nameFormatted
    const phone=  this.customerSel.customer.TELEFONO.replace(/[-\s]/g,'')


    let data={
      'firstName': name,
      'lastName' : lastName? lastName:name,
      'phone'    : this.countryCode + phone,
      'email'    : this.customerSel.customer.EMAIL
    }

    if (phone.length<10)
      urlCheckPhone='/checkPhone/'+this.countryCode + phone
    else
      urlCheckPhone='/checkPhone/'+ phone
     
    this.api.service(urlCheckPhone,'get').subscribe(res=>{
      if (res)
      {      
        console.log(res,'res de saveCustomerDB para comporbar si existe clienet') 
        if (res.client==null){
                this.api.service('/addClient','post',data).subscribe( cli=>{
                  if (cli){
                      idClient = cli.client.idClient
                      this.customerSel.customer.idClient= cli.client.idClient
                      if (this.customerSel.customer.REGISTRATION){
                          if (validateRegistration(this.customerSel.customer.REGISTRATION, this.registrationRegex))
                            this.checkRegistrationNumber(idClient)
                      }
                      if (!this.idDetail)
                          this.saveNewEvent(idClient)
                      else
                          this.editEvent()
                   }
                   else
                    this.error={message:'El teléfono está asignado a otro cliente',type:'phone'}
                  
                })
          }
        else{
            idClient = res.client.idClient
            this.customerSel.customer.idClient=idClient
            if (this.customerSel.customer.REGISTRATION){
              if (validateRegistration(this.customerSel.customer.REGISTRATION, this.registrationRegex))
               {this.checkRegistrationNumber(idClient)
                console.log('despues del checkregistration')
               }
   
              else this.error = {message :'El formato de la matrícula no es válida', type:'registration'}
              console.log(this.error,'error')
            }
            if(this.error.message==''){
              console.log('entra cuando no hay error')
              if (!this.idDetail)
                this.saveNewEvent(idClient)
              else
                this.editEvent()
            }
            
          }
      }
    })
          
  }

  saveRegistrationDB(idClient?:any)
  {  let idRegistrationNumber: any
      let data={
      'idClient': idClient?idClient:this.customerSel.customer.idClient,
      'registrationNumber': this.customerSel.customer.REGISTRATION
      }
      this.api.service('/addRegistrationNumber','post',data).subscribe( res=>{ 
          idRegistrationNumber = res.idClient
          return idRegistrationNumber
      })
  }

  changeTitle(event){
    event.stopPropagation()
    if (this.error)
    {
      if (this.error.type=='title' && this.title!='')
        this.error = {message:'',type:''}
    }
  
  }

  createTitle(servicesArr?: Array<any>, registration?: any, orFleet?: string, budget?:string, reference?: string){

    let servicesStr    : string =''
    let registrationStr: string
    let customerNameStr: string
    let orFleetStr     : String
    let budgetStr      : String
    let referenceStr   : String
    
    customerNameStr='CLIENTE:'
    registrationStr='MATRÍCULA:'
    orFleetStr     ='NRO. FLEET:'
    budgetStr      ='PRESUPUESTO:'
    referenceStr   ='REF. NEUMATICO:'


    if(this.customerSel.customer.NOMCLTE)
          customerNameStr=customerNameStr +' '+ this.customerSel.customer.NOMCLTE + '.' + ' '
    else
          customerNameStr=''
     
    if (Array.isArray(servicesArr))
      {   
          servicesStr='SERVICIOS:'
            servicesArr.forEach(item=>{
            servicesStr=servicesStr +' ' + item.name + '.' + ' '
        })
      }

    else if (this.taskSelected.name)
    {
      servicesStr='SERVICIOS INTERNOS:'
      servicesStr= servicesStr + ' ' + this.taskSelected.name
    }
          
    if (registration)
          registrationStr=' ' + registrationStr  + ' ' + registration + '.'
    else
          registrationStr=''

    if(orFleet)
        orFleetStr=' ' + orFleetStr + ' ' + orFleet
    
    else
        orFleetStr=''

    if(budget)
        budgetStr=' ' + budgetStr + ' ' + budget
      
    else
        budgetStr=''

    if (reference)
        referenceStr=' ' + referenceStr + ' ' + reference
    else
        referenceStr=''

    this.title= customerNameStr + servicesStr + registrationStr + orFleetStr + budgetStr + referenceStr
       
  }

  changeChkService(){

    this.service=!this.service

    if (this.service )
      {
        this.task =false
        this.serviceTBR=false
        this.servicesNamesSelectedArr=[]
       /* this.servicesNamesArrCopy.forEach(item=>{
          this.servicesNamesArr.push(JSON.parse(JSON.stringify(item)));
        })*/
       
      }
  
    if (!this.profitableServices)
      setTimeout(() => this.initializeIntlTelInput(), 0);

  }

  changeChkServiceTBR(){


    this.serviceTBR=!this.serviceTBR
    this.profitableServices=true


    if (this.serviceTBR )
      {
        this.task =false
        this.service=false
        this.servicesNamesSelectedArr=[]

       /* this.servicesNamesArrCopy.forEach(serv=>{
          filteredRow=serv.services.filter(row=>row.type=='AUTO-MOTO')
          if(filteredRow.length!=0)
          {
           this.servicesNamesTBRArr.push({idCategory:serv.idCategory, minutes: serv.minutes,name: serv.name ,services: filteredRow})
          }
          
         })*/
     /*   this.servicesNamesArrCopy.forEach(item=>{
          this.servicesNamesTBRArr.push(JSON.parse(JSON.stringify(item)));
        })*/
      
      }


      setTimeout(() => this.initializeIntlTelInput(), 0);




  }

  changeChkTask(){

    this.task=!this.task
    this.profitableServices=false

    if (this.task )
      {
        this.service =false
        this.serviceTBR=false
        this.iti=null

      }
    
      this.customerSel= {customer:{NOMCLTE: '', TELEFONO: '', EMAIL:'', REGISTRATION:'',REGISTRATIONS:null, idClient:'', budgets: null, budget:'', orFleets:null,orFleet:'',reference:''}};
      this.title=""

  }

  initializeIntlTelInput() {
 
    if (this.phoneInput) {
      const phoneInputField = this.phoneInput.nativeElement;
      if (this.customerSel.customer.TELEFONO.length>9) {
        this.getCountryInfoPhoneNumber(this.customerSel.customer.TELEFONO, this.initialCountryCode);
      }


      if (!this.iti)
      {
        
      this.iti = intlTelInput(phoneInputField, {
        utilsScript: "assets/utils.js",
        separateDialCode: false,
        initialCountry: this.initialCountryCode
      });

      phoneInputField.addEventListener('countrychange', () => {
        this.updateCountryCode();
        this.getCustomerByPhone();
      });
      }

    }
  }



  getTaskId(){

    this.arrExtrasServices.forEach(group=>{
       let arr: Array<any>=[] 
       arr= group.items
       arr.forEach(item=>{
        if ((item.name).toLowerCase() ===  (this.taskSelected.name).toLowerCase())
          {
            this.taskSelected.idTask=item.id
          }

      })
      
    })

    if (this.error.message!='' && this.error.type=='task') this.error={message:'',type:''}

    this.createTitle(this.taskSelected.name)
  
  }

  changeReference(){


      this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
    
       
  }


  changeBudget(){
    this.customerSel.customer.budget
   // this.verifiedBudget=false

   /* if (this.customerSel.customer.budget.match(this.budgetRegex)) {
      this.searchBudget(this.customerSel.customer.budget)
    } else {
      this.verifiedBudget=false
      this.error={message:'El formato inválido', type:'budget'}
    }*/

    this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
   // this.searchBudget(this.customerSel.customer.budget)
  }

  changeORFleet(){
    this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
  }

  changeRegistration(){
    this.customerSel.REGISTRATION= this.registration
    this.customerSel.customer.REGISTRATION= this.registration
    this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget, this.customerSel.customer.reference )
  }

  changeNameorg(){
    this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
    if(this.error.message!='' && this.error.type=="registration") this.error={message:'',type:''}
    
  }

  changeName(){
    console.log(this.customerSel,'customersel desde changeName')
    console.log('entra al changeName')
    if (this.customerSel.customer.idClient)
    {  
      
      let modalRefmessage=  this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,'¿Estas seguro que desea cambiar el nombre?', 'update')
      
      modalRefmessage.subscribe(res=>{
      if (res){
        switch(res["result"]){
          case "update":
          { 
            const data={
            firstName:this.customerSel.customer.NOMCLTE,
            lastName: ''
            }
            this.api.service('/editClient/' + this.customerSel.customer.idClient,'post',data).subscribe(
              res=>{
                if (res)
                {
                 console.log(res,'res de cambiar nombre al cliente')
                }
              }
            )
             break;
          }

          case "cancelar":
            {
              this.customerSel.customer.NOMCLTE= this.previousValue
             
            }
         }

         this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
        }
      })
    }
    
    //this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
    if(this.error.message!='' && this.error.type=="registration") this.error={message:'',type:''}
    
  }

  backupValue(){

    this.previousValue= this.customerSel.customer.NOMCLTE

  }



  setSelectRegistration(){
    this.inputRegistration=false
  }

  setInputRegistration(){
    this.inputRegistration= !this.inputRegistration

    if (!this.inputRegistration)
    {
      this.customerSel.customer.REGISTRATION= this.customerSel.customer.REGISTRATIONS[0]
      this.createTitle(this.servicesNamesSelectedArr, this.customerSel.customer.REGISTRATION,this.customerSel.customer.orFleet,this.customerSel.customer.budget,this.customerSel.customer.reference )
    }
    else
    {
      this.customerSel.customer.REGISTRATION=''
    }

  }

 
 ngOnChanges(changes: SimpleChanges){
    if(changes.message)
      {this.message=changes.message.currentValue}

  }

 
}


function validateTitle(field1?: any):boolean{

  if (field1=='' || field1==null)
    { 
      return false
    }
    else{
      return true
    }
}

function validateCalendario(field1?: Array<any>):boolean{

  
  if (field1.length==0)
    { 
      return false
    }
    else{
      return true
    }
}

function validatePhone(field1?:any, regex?:string):boolean{
  let minLength=9
  let maxLengh=12
  
  if ((field1=='' || field1==null))
  { 
    return false
  }
  else{
      if (field1.length<minLength || field1.length> maxLengh)
        return false
      else{
        console.log(field1.match(regex),'field1.match(regex)')
        if (field1.match(regex))
          return true 
        else
         return false
      }
       

  }
}

function validateCustomer(field1?:any):boolean{
  
  if (field1=='' || field1==null)
  { 
    return false
  }
  else{
    return true
  }
}

function validateServices(field1?: Array<any>):boolean{
  if (field1){
    if (field1.length==0)
    { 
      return false
    }
    else{
      return true
    }
  }
  else return false
  
 
}

function validateTask(field1?: any):boolean{

  if (!field1)
  { 
    return false
  }
  else{
    return true
  }
}

function validateRegistration(field1: string, regex: string):boolean{

  const regexResult= field1.match(regex)

  if (field1.length>6 && field1.length<9){
    if (!regexResult)
      { 
        return false
      }
      else{
      
          return true
       
      }
  }
  else return false
 
}

function validateFields(dateFrom: any, dateTo: any, field:any, arr: Array<any>, phone:any, customer: any, task:boolean, services?: any)
{

  let validatePhoneOK   : boolean
  let validateCustomerOK: boolean
  let validateServicesOK: boolean
  let validateTaskOK     : boolean

  let validateDateOK= validateDatesEN(dateFrom, dateTo,'-')
 // let validateTitleOK= validateTitle(field)
  let validateCalendarioOK= validateCalendario(arr)


  if (!task){
    validatePhoneOK     = validatePhone(phone)
    validateCustomerOK  = validateCustomer(customer)
    validateServicesOK  = validateServices(services)

  }
  else
  {
    validateServicesOK = true
    validateCustomerOK = true
    validatePhoneOK    = true
    validateTaskOK      = validateTask(services)

  }
  
 


    let error={message:'', type:''}

    if(!validateDateOK)
      {error = {message :'La fecha/ hora Desde no puede ser mayor que la fecha/hora Hasta', type:'date'}
     return error}
  
         
    if(!validateCalendarioOK)
      {error={message:'Debe selecionar al menos un operador', type:'calendars'}
      return error}

    if(!validatePhoneOK)
      {error={message:'El campo Teléfono no puede estar en blanco', type:'phone'}
      return error}

    if(!validateCustomerOK)
      {error={message:'El campo Cliente no puede estar en blanco', type:'customer'}
      return error}
    
    if (!validateServicesOK && !task)
      {error={message:'Debe seleccionar al menos un servicio', type:'services'}
      return error}

    if (!validateTaskOK && task)
      {error={message:'Debe seleccionar al menos una tarea', type:'task'}
      return error}
    
    if (error.message=='' && error.type=='')
      return error
            
        
}













