
import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';

@Component({
  selector: 'app-search-budget',
  templateUrl: './search-budget.component.html',
  styleUrls: ['./search-budget.component.css']
})
export class SearchBudgetComponent implements OnInit {

  @Output() Budget = new EventEmitter();
  @Output() closeBudgetSearch = new EventEmitter();
  @Input()  criteria: any;
  @Input() enterprise: any;

  constructor(private api: ApiService,private globalVariables: GlobalVariables) { }

  serial: string;
  budgetNumber: string
  error: boolean;
  budgets: Array<any>=[]
  loading: boolean
  searchText: any =""
  token: any;
  selectedDiv: number | null = null;
  msg: string
  imagepath: string=''
  

  ngOnInit(): void {

    this.imagepath=this.globalVariables.getUrlImage()

    console.log(this.criteria.field,'this.criteria.field')

  console.log(this.criteria,'this.criteria')

  if (!this.criteria.field)
  {
    console.log('entra cuando no es undefined')
  }

    if (this.criteria.field!='' || !this.criteria.field)
    {
     const indx =this.criteria.field.indexOf('/')
     console.log(indx,'indx')
     this.serial= this.criteria.field.substring(0,indx)
     this.budgetNumber= this.criteria.field.substring(indx+1)
    }

    console.log('entra al modal del search budget')
    console.log(this.criteria,'criteria')
    console.log(this.serial,'serial')
    console.log(this.budgetNumber,'budgetNumber')
  }

  searchBudget(event?){

    let formattedBudget: any
    let formattedSerial:any
    let formattedBudgetWithSerial: any
    let result: string
    let workplace: string
    let year: string

    console.log(this.serial,'serial')
    console.log(this.budgetNumber,'budgetNumber')

    this.budgets=[];
    this.error=false;
    formattedBudget=''
    formattedSerial=''
    formattedBudgetWithSerial=''

    result= removeSpacesAndSpecialChars(this.serial)

    console.log(result,'result')
    workplace= result.substring(0,result.length-2)
    year= result.substring(workplace.length, result.length)
    formattedSerial=workplace+'_'+year

    console.log(workplace,'workplace')
    console.log(year,'year')
    console.log(formattedSerial,'formattedSerial')

    formattedBudget=removeSpacesAndSpecialChars(this.budgetNumber);
  
    formattedBudgetWithSerial= formattedSerial + '/' + formattedBudget

    console.log(formattedBudgetWithSerial,'formattedBudgetWithSerial')
  
    this.searchBudgets(formattedBudgetWithSerial,this.enterprise)
  }

  selectBudget(event,item){
    console.log(item)
    this.closeEvent(item)
  }

  clearBudgets(event){
    event.stopPropagation();
    event.preventDefault();

    this.budgets=[]
    //this.criteria={field:'name',value:'' }
    this.loading=false
    this.msg=''
    this.serial=''
    this.budgetNumber=''

  }

  highlightDiv(index:number):void{
    this.selectedDiv = index;
  }

  onKey(value){

    this.searchBudget()

  }


  closeEvent(item?:any){

    console.log('entra al closeEvent')
    console.log(item,'item')
    
    if (item.budget)
      this.closeBudgetSearch.emit({budget:item})
    else if (this.criteria.field!='')
    { console.log('entra cuando no hay item')
      const budget={budget: {budget:this.criteria.field, id_budget:this.criteria.id}}
      this.closeBudgetSearch.emit(budget)
    }
      
  }

  searchBudgets(document:any, enterprise:any, id?:boolean){
    let data_budget: any
    let context_budget: any
    this.loading=true

    if (enterprise.length<2)
      enterprise= '0' + enterprise.toString()

    context_budget = `[{"ENTERPRISE_CODE":"${enterprise}"}]`

    if(!id){
        data_budget=`[{\"SEARCH_TEXT\":"${document}" ,\"ROWID_DIRECTO\":\"\"}]`
    }
    else
    {
        data_budget=`[{\"SEARCH_TEXT\":\"\" ,\"ROWID_DIRECTO\":"${document}"}]`
    
    }

    this.api.getTokenGTS().subscribe(res=>{
      this.token=res.value
      this.api.budgets(this.token,data_budget,context_budget).subscribe(res=>{
        if(res.value.Rows.length>0){
          res.value.Rows.forEach(row=>{
            if (row.NUM_PRESUPUESTO)
              {
                this.budgets.push({budget: row.NUM_PRESUPUESTO, id_budget:row._ROWID})

              }
             
            else{
               console.log('entra al else porque no hay presupuestos')
               this.msg='No hay presupuestos con el criterio de búsqueda actual'
               this.error=false
            }
            this.loading=false
             
          })
          console.log(this.budgets,'budgets')
        }
        else
        { this.error=false
          this.loading=false
          this.msg='No hay presupuestos con el criterio de búsqueda actual'
        }
        
      })
    
    })
 
    
  }

}

function removeSpacesAndSpecialChars(field: string){
  const result= field.replace(/[^a-zA-Z0-9]/g, "");
 return result
}


