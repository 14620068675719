import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Credentials } from '../models/credentials';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { getAuthString } from './utils.service';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

 private urlAPI="https://calendar-api.solucionait.es"
 private urlAPICalendar="https://calendar-api.solucionait.es/calendar"
 private urlApiBot="https://calendar-api.solucionait.es/bot"  
 //private urlAPIWhatsapp="http://localhost:3001"
 private urlAPIWhatsapp="https://sendmessage.solucionait.es"
 private urlAPIGTS = "https://ws.gruposoledad.com/gts"
 //private urlAPICalendar="http://localhost:3000/calendar"
 //private urlApiBot="http://localhost:3000/bot" 
 //private urlAPI="http://localhost:3000"


 private domain = "soledad";
 private publicKeyGTS = '@.A-FG##7989ABCGTPPO*RR5588A]RTC';
 private separatorGTS = '@';
 private userCodeGTS: string = 'genes';
 
  
 

  constructor(private http: HttpClient, private auth: AuthService, private router:Router) {
   }


  postLogin(userName: any, password: any): Observable<any>{
    return this.http.post(this.urlAPI+'/auth/login',{ "userName": userName,
    "password": password}
    )
   }

   getTokenGTS(): Observable<any> {
    return this.http.post(this.urlAPIGTS, {
      message: 'authenticate',
      authString: getAuthString(
        '#DCE#',
        'EMAIL',
        this.domain,
        this.publicKeyGTS,
        this.separatorGTS
      ),
      userCode: this.userCodeGTS,
    });
   }

   customer(token, data):Observable<any>{
   
     return this.http.post(this.urlAPIGTS, { 
     ENTITY: 'EXT_CUSTOMERS',
     CONTEXT_JSON: '[{"ENTERPRISE_CODE":"01"}]',
     PARAMS_JSON: data,
     PAGINA: 1,
     PageSize: 100,
     message: 'execute',
     serviceName: 'GTS.dbo.GTS_01_GRID',
     authToken: token})
   }

   vehicles(token, data):Observable<any>{

    return this.http.post(this.urlAPIGTS, { 
      ENTITY: 'EXT_VEHICLES',
      CONTEXT_JSON: '[{"ENTERPRISE_CODE":"01"}]',
      PARAMS_JSON: data,
      PAGINA: 1,
      PageSize: 10,
      message: 'execute',
      serviceName: 'GTS.dbo.GTS_01_GRID',
      authToken: token})

   }

   budgets(token,data, context):Observable<any>{
    return this.http.post(this.urlAPIGTS, {
          ENTITY: 'EXT_BUDGETS',
          CONTEXT_JSON: context,
          PARAMS_JSON: data,
          PAGINA: 1,
          PageSize: 100,
          message: 'execute',
          serviceName: 'GTS.dbo.GTS_01_GRID',
          authToken: token
    })
    
   }


   service(route, type,  data?, api_name?):any{
    return new Observable(observer=>{
      var cred  : Credentials
      const exp = this.auth.getTokenExpired(sessionStorage.getItem('authToken'))
      cred      = this.auth.getCredentials()
   
      if (this.auth.isTokenExpired(exp))
      {
        this.postLogin(cred.email, cred.password).subscribe(res=>{
          if(res){  
            if (res.token.length>0)
            {
              this.auth.saveToken(res.token,'')
              this.request(route, type,data, api_name).subscribe(res2=>{
                observer.next(res2);
                observer.complete()
              })
            
            }
          }
          //observer.next(res);
          //observer.complete(); 
         
        },
        
        error => {
          console.log(error);
         })
      }
      else{
      //  const headers = {'token':sessionStorage.getItem("authToken")}
        this.request(route, type,data,api_name,).subscribe(res=>{
          observer.next(res);
          observer.complete()
        })
      }
  
    })
       
   }


   request(route, type,  data?, api_name?):any{
    const headers = {'token':sessionStorage.getItem("authToken")}
    const headresWhatsapp =[]
    switch (type){
      case 'get':
        if (!data)
          return this.http.get(this.urlAPICalendar + route, {'headers':headers})
        else
          return this.http.get(this.urlApiBot + route, {'headers':headers} )
      case 'put':
        return  this.http.put(this.urlAPICalendar + route, data, {'headers':headers})

      case 'post':
        if(api_name=="whatsapp")
          return this.http.post(this.urlAPIWhatsapp + route, data)
        if (api_name=="bot" )
        {
          return this.http.post(this.urlApiBot + route, data, {'headers':headers} )
        }  
        else 
            return this.http.post(this.urlAPICalendar + route, data, {'headers':headers}  )  
         /* return this.http.post('http://localhost:3000' + route, data)*/
      
      case 'delete':
        if (!data)
         return this.http.delete(this.urlApiBot + route, {'headers':headers} )
        else 
        return this.http.delete(this.urlAPICalendar + route, {'headers':headers,'body':data})
    }
    return 
   }

  

  /* getWorkPlaces(): Observable <any>{
    const headers = {'token':sessionStorage.getItem("authToken")}
    return this.http.get(this.urlAPICalendar +'/workplaces', {'headers':headers})}
   

    getCalendarsByWorkPlace(id:any, data?:any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      //return this.http.get("https://calendar-api.solucionait.es/calendar/calendarsByWorkPlace/" + id,{'headers':headers}  )
      return this.http.get(this.urlAPICalendar +'/calendarsByWorkPlace/' + id,{'headers':headers}  )
    } 

    getCalendarsByWorkPlaceByDay(id:any, data?:any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.post(this.urlAPICalendar +'/calendarsByWorkPlace/' + id,data,{'headers':headers}  )
      //return this.http.post("https://calendar-api.solucionait.es/calendar/calendarsByWorkPlace/" + id,data,{'headers':headers}  )
    } 

    getCalendarsByWorkPlaceBySearchText(id:any, data?:any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.post(this.urlAPICalendar +'/calendarsByWorkPlace/' + id,data,{'headers':headers}  )
     // return this.http.post("https://calendar-api.solucionait.es/calendar/calendarsByWorkPlace/" + id,data,{'headers':headers}  )
    }

    addNewEvent(data: any): Observable <any>{
     const headers = {'token':sessionStorage.getItem("authToken")}
     return this.http.post(this.urlAPICalendar +'/addCalendarDetail',data,{'headers':headers})
    // return this.http.post("https://calendar-api.solucionait.es/calendar/addCalendarDetail",data,{'headers':headers})
    }

    deleteEvent(id: any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.delete(this.urlApiBot+'/calendarDetail/' + id,{'headers':headers})
    }

    deleteSeriesEvent(id: any, data?: any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.delete(this.urlAPICalendar +'/calendarDetail/' + id,{'headers':headers,'body':data})
    }

    updateEvent(id:any, data: any):Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.put(this.urlAPICalendar +'/calendarDetail/' + id,data,{'headers':headers})
    }

    reactivateEvent(id:any, data:any):Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.put(this.urlAPICalendar +'/calendarDetail/reactivate/'+ id,data,{'headers':headers})
    }

    getWorkWeekActiveByWorkplace(id: any, data?: any): Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.get(this.urlAPICalendar +'/workweekActive/' + id,{'headers':headers}  )

    }

    updateWorkWeekActiveByWorkplace(id:any, data:any):Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.put(this.urlAPICalendar +'/workweekActive/' + id,data,{'headers':headers})

    }

    createNewCalendarsByWorkPlace(id:any, data: any):Observable <any>{
      const headers = {'token':sessionStorage.getItem("authToken")}
      return this.http.post(this.urlAPICalendar +'/calendar/' + id,data,{'headers':headers})

      
    }
    updateCalendarsByWorkPlace(id:any, data:any):Observable<any>{
      const headers = {'token': sessionStorage.getItem("authToken")}
      return this.http.put(this.urlAPICalendar  + '/calendar/' + id, data,{'headers':headers})
    }

   sendMessages(data):Observable<any>{
    //const headers = {'message':"hola",'phone':"34644074320"}
    const headers = {'token': sessionStorage.getItem("authToken")}
    return this.http.post(this.urlAPIWhatsapp + '/message',data)
   }*/


}



/**
* Removes token for sessionStorage
* 
* @memberOf SoledadAuthenticationService
*/
function removeToken() {
  sessionStorage.removeItem("authToken");
  sessionStorage.removeItem("authTokenExtras");
}

